import { Box, CssBaseline } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import CookiePolicy from './views/CookiePolicy';
import Home from './views/Home';
import PrivacyPolicy from './views/PrivacyPolicy';
import SecurityPolicy from './views/SecurityPolicy';
import ClarificationText from './views/ClarificationText';
import UserAgreement from './views/UserAgreement';
import ConsentForm from './views/ConsentForm';
import KvkkRequestForm from './views/KvkkRequestForm';

function App() {
  return (
    <Box>
      <CssBaseline />
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/security-policy" element={<SecurityPolicy />} />
            <Route path="/clarification-text" element={<ClarificationText />} />
            <Route path="/user-agreement" element={<UserAgreement />} />
            <Route path="/consent-form" element={<ConsentForm />} />
            <Route path="/kvkk-request-form" element={<KvkkRequestForm />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </Box>
  );
}

export default App;
