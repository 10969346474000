import React from 'react'
import { useTranslation } from 'react-i18next';

export default function ClarificationText() {

    const { i18n } = useTranslation();

    const en = `
    <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
h1
	{margin-top:.1pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:27.1pt;
	text-align:center;
	text-autospace:none;
	font-size:12.5pt;
	font-family:"Times New Roman",serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin-top:5.8pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:41.9pt;
	text-align:justify;
	text-autospace:none;
	font-size:12.5pt;
	font-family:"Times New Roman",serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:5.4pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:27.1pt;
	text-align:justify;
	text-indent:-21.35pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
 /* Page Definitions */
 @page WordSection1
	{size:595.0pt 842.0pt;
	margin:67.0pt 66.0pt 63.8pt 65.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoBodyText align=left style='margin:0in;margin-bottom:.0001pt;
text-align:left'><span style='font-size:12.0pt'>&nbsp;</span></p>

<h1 style='margin-top:4.5pt;margin-right:184.3pt;margin-bottom:0in;margin-left:
184.5pt;margin-bottom:.0001pt;line-height:138%'><span style='font-size:12.0pt;
line-height:138%;letter-spacing:-.1pt'>DENTISTODAY</span></h1>

<p class=MsoNormal align=center style='margin-top:.1pt;margin-right:64.65pt;
margin-bottom:0in;margin-left:65.15pt;margin-bottom:.0001pt;text-align:center'><b><span
style='font-size:12.0pt'>CLARIFICATION</span></b><b><span style='font-size:
12.0pt;letter-spacing:3.15pt'> </span></b><b><span style='font-size:12.0pt'>TEXT</span></b><b><span
style='font-size:12.0pt;letter-spacing:1.55pt'> </span></b><b><span
style='font-size:12.0pt'>ON</span></b><b><span style='font-size:12.0pt;
letter-spacing:1.4pt'> </span></b><b><span style='font-size:12.0pt'>PROCESSING</span></b><b><span
style='font-size:12.0pt;letter-spacing:2.95pt'> </span></b><b><span
style='font-size:12.0pt'>PERSONAL</span></b><b><span style='font-size:12.0pt;
letter-spacing:2.0pt'> </span></b><b><span style='font-size:12.0pt;letter-spacing:
-.2pt'>DATA</span></b></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><span style='font-size:12.0pt;line-height:103%'>We, as (<b>“DentisToday”</b>
and/or <b>“Company”) </b>pay uttermost attention to the security of your
personal data. With this awareness, we as <b>DentisToday</b> attach<span
style='letter-spacing:-.8pt'> </span>great<span style='letter-spacing:-.8pt'> </span>importance<span
style='letter-spacing:-.55pt'> </span>to<span style='letter-spacing:-.75pt'> </span>the<span
style='letter-spacing:-.75pt'> </span>retention<span style='letter-spacing:
-.65pt'> </span>of<span style='letter-spacing:-.8pt'> </span>all<span
style='letter-spacing:-.8pt'> </span>kinds<span style='letter-spacing:-.6pt'> </span>of<span
style='letter-spacing:-.8pt'> </span>personal<span style='letter-spacing:-.5pt'>
</span>data<span style='letter-spacing:-.8pt'> </span>including<span
style='letter-spacing:-.35pt'> </span>your<span style='letter-spacing:-.6pt'> </span>personal
data<span style='letter-spacing:-.15pt'> </span>of<span style='letter-spacing:
-.4pt'> </span>special<span style='letter-spacing:-.25pt'> </span>data<span
style='letter-spacing:-.4pt'> </span>by<span style='letter-spacing:-.3pt'> </span>processed
the<span style='letter-spacing:-.3pt'> </span>same in<span style='letter-spacing:
-.3pt'> </span>the<span style='letter-spacing:-.2pt'> </span>best<span
style='letter-spacing:-.25pt'> </span>way<span style='letter-spacing:-.2pt'> </span>possible
and<span style='letter-spacing:-.2pt'> </span>with<span style='letter-spacing:
-.25pt'> </span>care.<span style='letter-spacing:-.4pt'> </span>With<span
style='letter-spacing:-.1pt'> </span>the awareness of this liability of ours,
we process your personal data, as the Data Controller within<span
style='letter-spacing:-.8pt'> </span>the<span style='letter-spacing:-.8pt'> </span>scope<span
style='letter-spacing:-.75pt'> </span>of<span style='letter-spacing:-.8pt'> </span>the<span
style='letter-spacing:-.8pt'> </span>Protection<span style='letter-spacing:
-.75pt'> </span>of<span style='letter-spacing:-.8pt'> </span>Personal<span
style='letter-spacing:-.75pt'> </span>Data<span style='letter-spacing:-.8pt'> </span>Law<span
style='letter-spacing:-.8pt'> </span>numbered<span style='letter-spacing:-.75pt'>
</span>6698<span style='letter-spacing:-.8pt'> </span>(the<span
style='letter-spacing:-.8pt'> </span>“Law”)<span style='letter-spacing:-.75pt'>
</span>and<span style='letter-spacing:-.8pt'> </span>the <span
style='letter-spacing:-.1pt'>Regulation</span><span style='letter-spacing:-.25pt'>
</span><span style='letter-spacing:-.1pt'>on</span><span style='letter-spacing:
-.6pt'> </span><span style='letter-spacing:-.1pt'>Personal</span><span
style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.1pt'>Health</span><span
style='letter-spacing:-.35pt'> </span><span style='letter-spacing:-.1pt'>Data</span><span
style='letter-spacing:-.6pt'> </span><span style='letter-spacing:-.1pt'>(the</span><span
style='letter-spacing:-.7pt'> </span><span style='letter-spacing:-.1pt'>“Regulation”)
and</span><span style='letter-spacing:-.65pt'> </span><span style='letter-spacing:
-.1pt'>the</span><span style='letter-spacing:-.7pt'> </span><span
style='letter-spacing:-.1pt'>related</span><span style='letter-spacing:-.55pt'>
</span><span style='letter-spacing:-.1pt'>legislation,</span><span
style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.1pt'>within</span><span
style='letter-spacing:-.5pt'> </span><span style='letter-spacing:-.1pt'>the
framework stated</span><span style='letter-spacing:-.2pt'> </span><span
style='letter-spacing:-.1pt'>below</span><span style='letter-spacing:-.2pt'> </span><span
style='letter-spacing:-.1pt'>and</span><span style='letter-spacing:-.5pt'> </span><span
style='letter-spacing:-.1pt'>in</span><span style='letter-spacing:-.7pt'> </span><span
style='letter-spacing:-.1pt'>accordance with</span><span style='letter-spacing:
-.6pt'> </span><span style='letter-spacing:-.1pt'>other</span><span
style='letter-spacing:-.4pt'> </span><span style='letter-spacing:-.1pt'>legislation.</span></span></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><span style='font-size:12.0pt;line-height:103%;letter-spacing:-.1pt'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><b><span style='font-size:12.0pt;line-height:103%;letter-spacing:-.1pt'>1.
Data Controller</span></b></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><b><span style='font-size:12.0pt;line-height:103%;letter-spacing:-.1pt'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><span style='font-size:12.0pt;line-height:103%'> “Aıdent Sağlık ve Diş
Teknolojileri Anonim Şirketi”, who operates at the address of “Rasimpaşa
Mahallesi Tayyareci Sami Sk. No: 13 B/1 Kadıköy/İSTANBUL” has the title of
“data controller” in accordance with the Law within the scope of providing the
Services.</span></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><span style='font-size:12.0pt;line-height:103%;letter-spacing:-.1pt'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><b><span style='font-size:12.0pt;line-height:103%'>2. Your Processed
Personal Data</span></b></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><b><span style='font-size:12.0pt;line-height:103%'>&nbsp;</span></b></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='margin-left:12.5pt;border-collapse:collapse;border:none'>
 <tr>
  <td width=144 valign=top style='width:107.75pt;border:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>Identifying Information</span></b></p>
  </td>
  <td width=444 valign=top style='width:332.85pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt'>Your
  name, surname, ID number, Date of Birth, Gender, Insurance or Patient
  Protocol Number, if any</span></p>
  </td>
 </tr>
 <tr>
  <td width=144 valign=top style='width:107.75pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>&nbsp;</span></b></p>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>Contact Information</span></b></p>
  </td>
  <td width=444 valign=top style='width:332.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt'>Your
  address, telephone number, e-mail address and other communication data, your
  voice call records kept by customer representatives or patient services in
  accordance with call center standards,</span></p>
  </td>
 </tr>
 <tr>
  <td width=144 valign=top style='width:107.75pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>&nbsp;</span></b></p>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>&nbsp;</span></b></p>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>&nbsp;</span></b></p>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>Health Data</span></b></p>
  </td>
  <td width=444 valign=top style='width:332.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt'>Your
  laboratory results, test results, examination data, appointment information,
  medical, medical and dental anamnesis data, x-ray results, radiological
  results, check-up information, prescription information, intraoral photo
  related to the application, etc. your health data and other personal data
  that you send to DenisToday mobile application. </span></p>
  </td>
 </tr>
 <tr style='height:92.15pt'>
  <td width=144 valign=top style='width:107.75pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt;height:92.15pt'>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>&nbsp;</span></b></p>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>&nbsp;</span></b></p>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>Web Site Usage Date</span></b></p>
  </td>
  <td width=444 valign=top style='width:332.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:92.15pt'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt'>Login
  Frequency/Times, Website User, Update Date, IP, Device ID, Used Operator
  Information, Device Information (Version-Model)</span></p>
  </td>
 </tr>
</table>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><b><span style='font-size:12.0pt;line-height:103%'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><span style='font-size:12.0pt;line-height:103%'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><span style='font-size:12.0pt;line-height:103%'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><span style='font-size:12.0pt;line-height:103%'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-indent:.05pt;line-height:
103%'><span style='font-size:12.0pt;line-height:103%'>&nbsp;</span></p>

<h1 style='margin-top:5.4pt;margin-right:0in;margin-bottom:0in;margin-left:
0in;margin-bottom:.0001pt;text-align:justify;text-indent:7.1pt'><span
style='font-size:12.0pt'>3. Collecting, Processing Personal Data and Purposes
of Processing</span></h1>

<p class=MsoBodyText style='margin-top:5.8pt;margin-right:5.2pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-indent:.1pt;line-height:103%'><span
style='font-size:12.0pt;line-height:103%'>Although your personal data processed
by <b>DentisToday</b> may vary depending on the health, physician services,
diagnosis and information provided, it is collected through automatic or
non-automatic ways, via call centers, internet sites, online services,
online/mobile applications and similar manners, in writing, orally or electronically.
Your personal data of special nature, particularly your health data and your
personal data in general nature and the ones stated above can be processed by <b>DentisToday</b>
being relevant with, limited to and proportionate to the purposes of health
care services given below.</span></p>

<p class=MsoBodyText style='margin-top:5.25pt;margin-right:0in;margin-bottom:
0in;margin-left:5.9pt;margin-bottom:.0001pt'><b><i><span style='font-size:12.0pt'>&nbsp;</span></i></b></p>

<p class=MsoBodyText style='margin-top:5.25pt;margin-right:0in;margin-bottom:
0in;margin-left:5.9pt;margin-bottom:.0001pt'><span style='font-size:12.0pt'>All
kinds of your personal data (including the personal data of special nature)
obtained by</span></p>

<p class=MsoBodyText style='margin-top:.5pt;margin-right:0in;margin-bottom:
0in;margin-left:5.9pt;margin-bottom:.0001pt'><b><span style='font-size:12.0pt'>DentisToday</span></b><span
style='font-size:12.0pt'> can be processed, generally, to process public
health, to conduct preventive medicine, preliminary and final medical
diagnosis, physician referral, medical diagnosis, treatment and nursing
services including artificial intelligence management, and within such scope to
improve the online shopping (health products) services offered on the website,
and can be also processed for and limited to the following purposes:</span></p>

<p class=MsoBodyText style='margin-top:4.65pt;margin-right:0in;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To confirm your identity.</span></p>

<p class=MsoBodyText style='margin-top:5.55pt;margin-right:5.4pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
103%'><span style='font-size:12.0pt;line-height:103%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:103%'>To protect public
health, to conduct preventive medicine, medical diagnosis, treatment and
nursing services, planning and management of health care services and their
financing.</span></p>

<p class=MsoBodyText style='margin-top:5.35pt;margin-right:6.2pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
103%'><span style='font-size:12.0pt;line-height:103%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:103%'>To share
information requested by the Ministry of Health and other public institutions
and organizations as per the related legislation.</span></p>

<p class=MsoBodyText style='margin-top:4.9pt;margin-right:5.5pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
105%'><span style='font-size:12.0pt;line-height:105%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:105%'>To plan and
manage daily operations in hospitals or dental clinics upon your confirmation.</span></p>

<p class=MsoBodyText style='margin-top:4.85pt;margin-right:5.9pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
105%'><span style='font-size:12.0pt;line-height:105%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:105%'>To measure,
increase and search the satisfaction of patients by the Hospital Management,
Patient Rights, and Patient Experience departments.</span></p>

<p class=MsoBodyText style='margin-top:4.85pt;margin-right:0in;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To supply medicines.</span></p>

<p class=MsoBodyText style='margin-top:5.8pt;margin-right:57.65pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
138%'><span style='font-size:12.0pt;line-height:138%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:138%'>To inform you
regarding your appointment in case you get an appointment. To direct you to the
most appropriate physician for you, in case you request.</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.35pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
103%'><span style='font-size:12.0pt;line-height:103%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:103%'>To share your
roentgen, radiologic documents and oral and dental and related photos and
videos that you have sent and other data of yours in connection with these with
the physician.</span></p>

<p class=MsoBodyText style='margin-top:5.2pt;margin-right:5.2pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
103%'><span style='font-size:12.0pt;line-height:103%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:103%'>To use as
analysis the application that will be used as artificial intelligence and
services that you have received from our Application</span></p>

<p class=MsoBodyText style='margin-top:4.9pt;margin-right:5.5pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
120%'><span style='font-size:12.0pt;line-height:120%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:120%'>To offer you the
most appropriate service by categorizing options according to your preferences
and needs in case you use our shopping and online market services.</span></p>

<p class=MsoBodyText style='margin-top:0in;margin-right:5.2pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
120%'><span style='font-size:12.0pt;line-height:120%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:120%'>To fulfill the
financing of your health services, your costs of examination, diagnosis and
treatment by the Patient Services, Financial Affairs, Marketing departments, to
share information requested by private insurance companies within the document
to which Social Security Institution you are registered.</span></p>

<p class=MsoBodyText style='margin-top:4.5pt;margin-right:0in;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in'><span
style='font-size:12.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt'>To fulfill legal and regulatory
requirements.</span></p>

<p class=MsoBodyText style='margin-top:5.8pt;margin-right:5.85pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
103%'><span style='font-size:12.0pt;line-height:103%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:103%'>To conduct risk
management and quality development activities by Quality, Patient Experience,
Information Security departments.</span></p>

<p class=MsoBodyText style='margin-top:5.8pt;margin-right:5.85pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
103%'><span style='font-size:12.0pt;line-height:103%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:103%'>To invoice
against our services and to confirm your relationship with contracted
institutions by Patient Services, Financial Affairs, Marketing departments.</span></p>

<p class=MsoBodyText style='margin-top:5.8pt;margin-right:5.85pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
103%'><span style='font-size:12.0pt;line-height:103%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:103%'>To confirm your
relationship with institutions contracted <b>DentisToday,</b></span></p>

<p class=MsoBodyText style='margin-top:5.8pt;margin-right:5.85pt;margin-bottom:
0in;margin-left:1.0in;margin-bottom:.0001pt;text-indent:-.25in;line-height:
103%'><span style='font-size:12.0pt;line-height:103%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;line-height:103%'>To use as
evidence in disputes to be arisen.</span></p>

<p class=MsoBodyText style='margin-top:5.8pt;margin-right:5.85pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;line-height:103%'><span
style='font-size:12.0pt;line-height:103%'>Your Personal Data, obtained and process
as per the related legislation, can be retained both in digital and physical
media by transferring the same to <b>DentisToday</b> or  physical achieves
and/or information systems belong to <b>DentisToday</b>.</span></p>

<p class=MsoBodyText style='margin-top:5.8pt;margin-right:5.85pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;line-height:103%'><span
style='font-size:12.0pt;line-height:103%'>Furthermore, your personal data
stated above, can be processed within the framework of the provisions of Health
Care Services Fundamental Law No. 3359, Decree No. 663 on the Organization and
Duties of the Ministry of Health and its Affiliates, the Regulation on Private
Hospitals, Regulation on the Processing of Personal Health Data and Protection
of Privacy and regulations of the Ministry of Health and other legislation, and
your personal data can be retained both in digital and physical media by
transferring the same to  <b>DentisToday</b> or physical achieves and/or
information systems belong to our physicians that we advise upon your consent.</span></p>

<p class=MsoNormal style='margin-top:3.35pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:5.75pt;margin-bottom:.0001pt;text-align:justify;text-indent:
.05pt;line-height:108%'><span style='font-size:12.0pt;line-height:108%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:3.35pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;line-height:108%'><b><span
style='font-size:12.0pt;line-height:108%'>4. Transfer<span style='letter-spacing:
.7pt'> </span>of<span style='letter-spacing:.35pt'> </span>Personal<span
style='letter-spacing:1.1pt'> </span><span style='letter-spacing:-.2pt'>Data</span></span></b></p>

<p class=MsoNormal style='margin-top:6.1pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;line-height:108%'><span
style='font-size:12.0pt;line-height:108%'>Your persona data can be shared,
within the scope of the Law, Regulation and other legislation and for the above
given purposes, with the application of <b>DentisToday</b><b> </b>the
physicians and hospitals that it works with, the Ministry of Health, its
sub-units and family practice centers, private insurance companies (health,
pension and health insurance and similar), the Social Security Institution,
Security General Directorate and other law- enforcement offices, Civil Registry
General Directorate, Turkish Pharmacists' Association, courts, laboratories
within the country which we cooperate with for medical diagnosis, medical
centers<span style='letter-spacing:-.1pt'> </span>and<span style='letter-spacing:
-.15pt'> </span>third<span style='letter-spacing:-.15pt'> </span>persons<span
style='letter-spacing:-.15pt'> </span>provide health<span style='letter-spacing:
-.2pt'> </span>care<span style='letter-spacing:-.2pt'> </span>services, health<span
style='letter-spacing:-.2pt'> </span>care<span style='letter-spacing:-.2pt'> </span>institution
to<span style='letter-spacing:-.4pt'> </span>which the patient is directed or
that the patient applied to, our representatives that we grant authorization,
third parties from which we receive consultancy, regulatory and auditor
institutions and public offices, including institution, attorneys at law, tax
consultants and auditors that you are associated with and/or work with, our
suppliers that we<span style='letter-spacing:-.05pt'> </span>get benefit from
their services or<span style='letter-spacing:-.15pt'> </span>with whom we<span
style='letter-spacing:-.1pt'> </span>cooperate, our support service provider
and business partners (in order to get further information, you may kindly
apply to <b>DentisToday</b><b> </b>in writing), and such personal data shall
not be shared with third persons other than the ones indicated.</span></p>

<p class=MsoNormal style='margin-top:6.1pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;text-indent:.1pt;
line-height:108%'><span style='font-size:12.0pt;line-height:108%'>Your health
data is only transferred to the physicians that you have given your explicit
consent to the data transfer and who have confidentiality obligation.</span></p>

<p class=MsoNormal style='margin-top:6.1pt;margin-right:5.35pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;text-indent:.1pt;
line-height:108%'><b><span style='font-size:12.0pt;line-height:108%'>5.</span></b><span
style='font-size:12.0pt;line-height:108%'> <b><span style='letter-spacing:-.1pt'>Method</span><span
style='letter-spacing:-.05pt'> </span><span style='letter-spacing:-.1pt'>and</span><span
style='letter-spacing:-.25pt'> </span><span style='letter-spacing:-.1pt'>Legal
Ground</span><span style='letter-spacing:.25pt'> </span><span style='letter-spacing:
-.1pt'>of</span><span style='letter-spacing:-.45pt'> </span><span
style='letter-spacing:-.1pt'>Collecting</span><span style='letter-spacing:.2pt'>
</span><span style='letter-spacing:-.1pt'>Personal</span><span
style='letter-spacing:.15pt'> </span><span style='letter-spacing:-.2pt'>Data</span></b></span></p>

<p class=MsoNormal style='margin-top:6.35pt;margin-right:5.4pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;text-indent:.05pt;
line-height:108%'><span style='font-size:12.0pt;line-height:108%'>Your personal
data is processed by automatic or non-automatic means via telephone, web
environment, while using the application and while using our clinic and other
services, automatically,<span style='letter-spacing:-.15pt'> </span>by basing
on the legal ground of “being mandatory for the legitimate interest of the data
controller, provided that this processing shall not violate the fundamental
rights and freedoms of the person concerned” stated under Article 5 of the Law.</span></p>

<p class=MsoNormal style='margin-top:6.35pt;margin-right:5.4pt;margin-bottom:
0in;margin-left:5.85pt;margin-bottom:.0001pt;text-align:justify;text-indent:
.05pt;line-height:108%'><span style='font-size:12.0pt;line-height:108%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:6.35pt;margin-right:5.4pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;text-indent:.05pt;
line-height:108%'><span style='font-size:12.0pt;line-height:108%'>The majority
of the personal data listed above is provided by you in order to use the mobile
application. The data which is collected automatically is Web Site/Mobile
Application usage data only.</span></p>

<p class=MsoNormal style='margin-top:6.35pt;margin-right:5.4pt;margin-bottom:
0in;margin-left:5.85pt;margin-bottom:.0001pt;text-align:justify;text-indent:
.05pt;line-height:108%'><span style='font-size:12.0pt;line-height:108%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:6.35pt;margin-right:5.4pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;text-indent:.05pt;
line-height:108%'><b><span style='font-size:12.0pt;line-height:108%'>6. Data We
Collect Through Cookies</span></b></p>

<p class=MsoNormal style='margin-top:6.35pt;margin-right:5.4pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;text-indent:.05pt;
line-height:108%'><span style='font-size:12.0pt;line-height:108%'>Technologies
such as cookies and web beacons are used for advertising and marketing
purposes, tracking your shopping experience and managing the operation of the
Site so that we can provide you with a better, faster and safer experience when
you visit our site or use our services.</span></p>

<p class=MsoNormal style='margin-top:6.35pt;margin-right:5.4pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;text-indent:.05pt;
line-height:108%'><span style='font-size:12.0pt;line-height:108%'>For more
detailed information on our use of these technologies and how you can control
them, research our <b>Cookie Policy.</b></span></p>

<p class=MsoNormal style='margin-top:6.35pt;margin-right:5.4pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;text-indent:.05pt;
line-height:108%'><span style='font-size:12.0pt;line-height:108%'>Also, many
popular browsers allow you to control cookies.</span></p>

<p class=MsoNormal style='margin-top:6.35pt;margin-right:5.4pt;margin-bottom:
0in;margin-left:0in;margin-bottom:.0001pt;text-align:justify;text-indent:.05pt;
line-height:108%'><span style='font-size:12.0pt;line-height:108%'>To learn how
to control cookies for some popular browsers, we provide you links that belongs
to these popular browsers below.</span></p>

<p class=MsoListParagraph style='margin-left:.5in;text-indent:-.25in;
text-autospace:ideograph-numeric ideograph-other'><span style='font-size:12.0pt;
font-family:Wingdings'>v<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt'>Google Chrome&nbsp;<a
href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en"><span
style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraph style='margin-left:.5in;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:.5in;text-indent:-.25in;
text-autospace:ideograph-numeric ideograph-other'><span style='font-size:12.0pt;
font-family:Wingdings'>v<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt'>Microsoft Edge&nbsp;<a
href="https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"><span
style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:.5in;text-indent:-.25in;
text-autospace:ideograph-numeric ideograph-other'><span style='font-size:12.0pt;
font-family:Wingdings'>v<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt'>Mozilla Firefox&nbsp;<a
href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"><span
style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraph style='margin-left:.5in;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:.5in;text-indent:-.25in;
text-autospace:ideograph-numeric ideograph-other'><span style='font-size:12.0pt;
font-family:Wingdings'>v<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt'>Microsoft Internet Explorer&nbsp;<a
href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"><span
style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:.5in;text-indent:-.25in;
text-autospace:ideograph-numeric ideograph-other'><span style='font-size:12.0pt;
font-family:Wingdings'>v<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt'>Opera&nbsp;<a
href="https://www.opera.com/help/tutorials/security/privacy/"><span
style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraph style='margin-left:.5in;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:.5in;text-indent:-.25in;
text-autospace:ideograph-numeric ideograph-other'><span style='font-size:12.0pt;
font-family:Wingdings'>v<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt'>Apple Safari&nbsp;<a
href="https://support.apple.com/en-gb/safari"><span style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='text-autospace:ideograph-numeric ideograph-other'><b><span
style='font-size:12.0pt'>7. Data Security </span></b></p>

<p class=MsoListParagraph style='text-autospace:ideograph-numeric ideograph-other'><b><span
style='font-size:12.0pt'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:5.75pt;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><b><span style='font-size:12.0pt'>DentisToday</span></b><span
style='font-size:12.0pt'>, meticulously protects your personal data within our
technical and administrative capabilities. The security measures taken by our
institution are provided at a suitable level for possible risks, by taking the
technological possibilities into consideration .You can review all the measures
taken on the data security tab on VERBIS.</span></p>

<p class=MsoListParagraph style='margin-left:5.75pt;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:5.75pt;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>All of the
data obtained from our users is kept in local cloud systems, and these cloud
system providers provide their services in accordance with local and
international data security standards.</span></p>

<p class=MsoListParagraph style='margin-left:5.75pt;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:5.75pt;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:5.75pt;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph align=left style='margin:0in;margin-bottom:.0001pt;
text-align:left;text-indent:0in;line-height:115%;text-autospace:ideograph-numeric ideograph-other'><b><span
lang=EN-GB style='font-size:12.0pt;line-height:115%'>8. Your Rights</span></b></p>

<p class=MsoNormal style='line-height:115%'><b><span lang=EN-GB
style='font-size:12.0pt;line-height:115%'>&nbsp;</span></b></p>

<p class=MsoNormal style='line-height:115%'><span lang=EN-GB style='font-size:
12.0pt;line-height:115%'>Personal Data Owners have the following rights in
accordance with the article 11 of the Law;</span></p>

<p class=MsoNormal style='line-height:115%'><span lang=EN-GB style='font-size:
12.0pt;line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:78.0pt;margin-bottom:.0001pt;text-indent:-78.0pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><span lang=EN-GB
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;line-height:115%'>To
learn as to whether personal data of Data Owner is processed or not by Aident,</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:-35.45pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><span lang=EN-GB
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;line-height:115%'>To
request information on personal processing activity, if such Personal Data is processed
before </span><b><span style='font-size:12.0pt;line-height:115%'>DentisToday</span></b><span
lang=EN-GB style='font-size:12.0pt;line-height:115%'>,</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:-35.45pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><span lang=EN-GB
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;line-height:115%'>To
learn the purpose of her/his personal data processing and whether such data is
used for the intended purpose, if such Personal Data is processed before </span><b><span
style='font-size:12.0pt;line-height:115%'>DentisToday</span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:-35.45pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><span lang=EN-GB
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;line-height:115%'>To
request information on third parties to whom Personal Data is transferred at
home or abroad,</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:78.0pt;margin-bottom:.0001pt;text-indent:-78.0pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><span lang=EN-GB
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;line-height:115%'>To
request rectification of the incomplete and inaccurate Personal Data, if any,</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:-35.45pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><span lang=EN-GB
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;line-height:115%'>To
request the notification for the incomplete and inaccurate Personal Data
processed before </span><b><span style='font-size:12.0pt;line-height:115%'>DentisToday</span></b><span
lang=EN-GB style='font-size:12.0pt;line-height:115%'> to third persons to whom
such Personal Data is transferred, </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:-35.45pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><span lang=EN-GB
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;line-height:115%'>To
request the erasure or destruction or anonymization of Personal Data in case
the reasons of processing such Personal Data are disappeared although such
Personal Data is processed in accordance with the provisions of the Law and
other related laws, </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:-35.45pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><span lang=EN-GB
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;line-height:115%'>To
request the notification of such circumstance where the persons of processing
Personal Data is disappeared to third persons to whom Personal Data has been
transferred,</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:-35.45pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><span lang=EN-GB
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;line-height:115%'>To
object to the analysing results that are against the Personal Data Owner whose
Personal Data processed by </span><b><span style='font-size:12.0pt;line-height:
115%'>DentisToday</span></b><span style='font-size:12.0pt;line-height:115%'> <span
lang=EN-GB>has been analysed exclusively by automatic means, </span></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:-35.45pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><span lang=EN-GB
style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;line-height:115%'>To
request compensation for the damages arising out of the illegal processing of
Personal Data.</span></p>

<p class=MsoListParagraph style='margin-top:0in;line-height:115%;text-autospace:
ideograph-numeric ideograph-other'><span lang=EN-GB style='font-size:12.0pt;
line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0in;text-indent:-27.1pt;line-height:
115%;text-autospace:ideograph-numeric ideograph-other'><b><span lang=EN-GB
style='font-size:12.0pt;line-height:115%'>9. Contact</span></b></p>

<p class=MsoListParagraph style='margin-left:5.75pt;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:0in;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>You can
exercise the above-mentioned rights in accordance with the Communiqué on the
Procedures and Principles of Application to the Data Controller. When you want
to exercise your rights, you can contact us in the following ways; By filling
out the <b>&quot;Application Form Under the Law on Protection of Personal
Data&quot;</b> at https://www.theaident.com or http://dt.theaident.com for your
requests under the law;</span></p>

<p class=MsoListParagraph style='margin-left:5.75pt;text-indent:0in;text-autospace:
ideograph-numeric ideograph-other'><span style='font-size:12.0pt'>&nbsp;</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 width=626
 style='width:469.4pt;margin-left:5.4pt;border-collapse:collapse;border:none'>
 <tr style='height:31.8pt'>
  <td width=112 valign=top style='width:84.0pt;border:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:31.8pt'>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>APPLICATION METHOD</span></b></p>
  </td>
  <td width=154 valign=top style='width:115.8pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt;height:31.8pt'>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>Written Application</span></b></p>
  </td>
  <td width=220 valign=top style='width:165.1pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt;height:31.8pt'>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>Application via E-Mail Address</span></b></p>
  </td>
  <td width=139 valign=top style='width:104.5pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt;height:31.8pt'>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>Application via Registered E-Mail (KEP) Address</span></b></p>
  </td>
 </tr>
 <tr style='height:41.45pt'>
  <td width=112 valign=top style='width:84.0pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt;height:41.45pt'>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>APPLICATION ADDRESS</span></b></p>
  </td>
  <td width=154 valign=top style='width:115.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:41.45pt'>
  <p class=MsoNormal align=center style='text-align:center'><span lang=TR
  style='font-size:12.0pt'>Rasimpaşa Mahallesi Tayyareci Sami Sok. No: 13/B İç
  Kapı No:125 Kadıköy/İSTANBUL</span></p>
  </td>
  <td width=220 valign=top style='width:165.1pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:41.45pt'>
  <p class=MsoNormal align=center style='text-align:center'><span lang=TR
  style='font-size:12.0pt'>info@theaident.com</span></p>
  </td>
  <td width=139 valign=top style='width:104.5pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:41.45pt'>
  <p class=MsoNormal align=center style='text-align:center'><span
  style='font-size:12.0pt'>aident@hs01.kep.tr</span></p>
  </td>
 </tr>
 <tr style='height:42.0pt'>
  <td width=112 valign=top style='width:84.0pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt;height:42.0pt'>
  <p class=MsoNormal align=center style='text-align:center'><b><span
  style='font-size:12.0pt'>INFORMATION TO INDICATE IN THE APPLICATION</span></b></p>
  </td>
  <td width=154 valign=top style='width:115.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:42.0pt'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt'>&quot;Information
  Request Under the Law on Protection of Personal Data&quot; will be written on
  the envelope/notification.</span></p>
  </td>
  <td width=220 valign=top style='width:165.1pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:42.0pt'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt'>You
  can send a &quot;word&quot; or &quot;pdf&quot; format file addressed to <b>DentisToday</b>
  by signing with a secure e-signature and sending it to info@theaident.com by
  writing &quot;Personal Data Protection Law Information Request&quot; in the
  subject line of the e-mail.</span></p>
  </td>
  <td width=139 valign=top style='width:104.5pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:42.0pt'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt'>You
  can send it with a secure electronic or mobile signature, via your registered
  e-mail address or your e-mail address registered in our system, by writing
  &quot;Information Request Under the Law on the Protection of Personal
  Data&quot; in the subject line.</span></p>
  </td>
 </tr>
</table>

<p class=MsoListParagraph style='margin-left:113.75pt;text-indent:0in;
text-autospace:ideograph-numeric ideograph-other'><b><span style='font-size:
12.0pt'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:113.75pt;text-indent:0in;
text-autospace:ideograph-numeric ideograph-other'><b><span style='font-size:
12.0pt'>Imprint Information of the Data Controller</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:12.0pt'>Trade
Name                                       :</span></b> <span style='font-size:
12.0pt'>Aıdent Sağlık ve Diş Teknolojileri Anonim Şirketi</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:12.0pt'>Central
Registration System No      :</span></b><span style='font-size:12.0pt'> 0010208562500001</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:12.0pt'>Address                                              :</span></b><span
style='font-size:12.0pt'>Rasimpaşa Mahallesi Tayyareci Sami Sk. No: 13 B/1
Kadıköy/İSTANBUL</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:12.0pt'>Contact                                               :</span></b><span
style='font-size:12.0pt'><a href="mailto:info@theaident.com"><span
style='color:windowtext'>info@theaident.com</span></a></span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:12.0pt'>            </span></b></p>

</div>

</body>

</html>

    
    `;
    const tr = `
    <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p
	{margin-right:0in;
	margin-left:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center;line-height:normal'><b><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif;border:
none windowtext 1.0pt;padding:0in'>DENTISTODAY</span></b><b><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span></b></p>

<p class=MsoNormal align=center style='text-align:center;line-height:normal'><b><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>KİŞİSEL
VERİLERİN İŞLENMESİ HAKKINDA AYDINLATMA METNİ</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Aıdent Sağlık ve
Diş Teknolojileri Anonim Şirketi </span><span lang=TR style='font-size:12.0pt;
font-family:"Times New Roman",serif'>(“<b><span style='border:none windowtext 1.0pt;
padding:0in'>DentisToday</span></b><span style='border:none windowtext 1.0pt;
padding:0in'>”<b> </b>ve/veya<b> “TheAident” </b>ve/veya<b> </b>“<b>Şirket</b></span>”)
olarak, kişisel verilerinizin güvenliği hususuna yüksek derecede hassasiyet
göstermekteyiz. Bu bilinçle, <b><span style='border:none windowtext 1.0pt;
padding:0in'>DentisToday</span></b> olarak tarafınıza ait özel nitelikli
kişisel verileriniz dahil her türlü kişisel verinin mümkün olabilen en iyi
şekilde ve özenle işlenerek muhafaza edilmesine büyük önem vermekteyiz. Bu
sorumluluğumuzun bilinci ile 6698 sayılı Kişisel Verilerin Korunması Kanunu
(“Kanun”), Kişisel Sağlık Verileri Hakkında Yönetmelik (“Yönetmelik”) ve ilgili
mevzuat kapsamında Veri Sorumlusu sıfatıyla, kişisel verilerinizi aşağıda
belirtilen çerçevede ve sair mevzuata uygun şekilde işlemekteyiz.</span></p>

<p class=MsoListParagraph style='margin-left:14.2pt;text-align:justify;
text-indent:-14.2pt;line-height:normal'><b><span lang=TR style='font-size:12.0pt;
font-family:"Times New Roman",serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Veri
Sorumlusu</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>“Rasimpaşa Mahallesi
Tayyareci Sami Sk. No: 13 B/1 Kadıköy/İSTANBUL” adresinde faaliyet gösteren “AIDENT
SAĞLIK VE DİŞ TEKNOLOJİLERİ ANONİM ŞİRKETİ”, Hizmetler'in sunulması kapsamında
Kanun uyarınca “veri sorumlusu” sıfatını taşımaktadır.</span></p>

<p class=MsoListParagraph style='margin-left:14.2pt;text-align:justify;
text-indent:-14.2pt;line-height:normal'><b><span lang=TR style='font-size:12.0pt;
font-family:"Times New Roman",serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>İşlenen
Kişisel Verileriniz</span></b></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-size:
12.0pt;line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=160 valign=top style='width:120.25pt;border:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kimlik
  Bilgileri</span></b></p>
  </td>
  <td width=444 valign=top style='width:332.85pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Adınız,
  Soyadınız, T.C. Kimlik numaranız, Doğum Tarihiniz, Cinsiyetiniz</span></p>
  </td>
 </tr>
 <tr>
  <td width=160 valign=top style='width:120.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>İletişim
  Bilgileri</span></b></p>
  </td>
  <td width=444 valign=top style='width:332.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Adresiniz,
  telefon numaranız, elektronik posta adresiniz ve sair iletişim verileriniz, </span></p>
  </td>
 </tr>
 <tr>
  <td width=160 valign=top style='width:120.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Sağlık
  Verileri</span></b></p>
  </td>
  <td width=444 valign=top style='width:332.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Laboratuvar
  sonuçlarınız, test sonuçlarınız, muayene verileriniz, randevu bilgileriniz,
  tıbbi,  medikal ve dental anamnez verileriniz, röntgen sonuçlarınız,
  radyolojik sonuçlar, check-up bilgileriniz, reçete bilgileriniz, uygulama ile
  bağlantılı  ağız içi fotoğraf vb veriler ile mobil uygulamamıza yüklediğiniz sağlık
  verileriniz ve sair kişisel verileriniz</span></p>
  </td>
 </tr>
 <tr style='height:92.15pt'>
  <td width=160 valign=top style='width:120.25pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt;height:92.15pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Web
  Sitesi Kullanım Verileri</span></b></p>
  </td>
  <td width=444 valign=top style='width:332.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:92.15pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Siteye
  Login Olma(Giriş Yapma) Sıklığı/Zamanları, Web Site Kullanıcısı, Güncelleme
  Tarihi, IP, Device ID, Kullandığı Operatör Bilgisi, Cihaz Bilgisi
  (Versiyon-Model)</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-left:.25in;text-align:justify;line-height:
normal'><b><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:14.2pt;text-align:justify;
text-indent:-14.2pt;line-height:normal'><b><span lang=TR style='font-size:12.0pt;
font-family:"Times New Roman",serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kişisel
Verilerin Toplanması, İşlenmesi ve İşleme Amaçları</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif;border:
none windowtext 1.0pt;padding:0in'>DentisToday</span></b><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'> tarafından
işlenen kişisel verileriniz, verilen sağlık, hekim hizmetleri ile tanı ve
bilgilendirmelere bağlı olarak değişebilmekle birlikte otomatik ya da otomatik
olmayan yöntemlerle, çağrı merkezi, internet sitesi, online hizmetler, online/mobil
uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da elektronik olarak
toplanmaktadır. Sağlık verileriniz başta olmak üzere özel nitelikli kişisel
verileriniz ve genel nitelikli kişisel verileriniz ile aşağıda yer alan kişisel
verileriniz, <b><span style='border:none windowtext 1.0pt;padding:0in'>DentisToday</span></b>
tarafından yine aşağıda belirtilen sağlık hizmeti amaçlarıyla bağlantılı,
sınırlı ve ölçülü şekilde işlenebilecektir;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif;border:
none windowtext 1.0pt;padding:0in'>DentisToday</span></b><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'> tarafından
elde edilen her türlü kişisel veriniz (Özel nitelikli kişisel veriler de dahil)</span><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>, genel
olarak kamu sağlığının korunması, koruyucu hekimlik, ön tanı, kesin tanı, hekim
yönlendirmesi, yapay zekâ yönetimi dahil tıbbî teşhis, tedavi ve bakım
hizmetlerinin yürütülmesi ve yine bu kapsamda internet sitesinde sunulan online
alışveriş (sağlık ürünleri) hizmetlerinin iyileştirilmesi amacıyla
işlenebilecek ise de <span style='color:black'>aşağıdaki amaçlar ile de işlenebilecektir:</span></span></p>

<ul type=disc>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><a
     name="_Hlk69069656"><span lang=TR style='font-size:12.0pt;font-family:
     "Times New Roman",serif'>Kimliğinizi teyit etme.</span></a></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kamu
     sağlığının korunması, koruyucu hekimlik, tıbbî teşhis, tedavi ve bakım
     hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması
     ve yönetimi.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>İlgili
     mevzuat uyarınca Sağlık Bakanlığı ve diğer kamu kurum ve kuruluşları ile
     talep edilen bilgilerin paylaşılması.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Onayınız
     ile hastane veya diş polikliniklerinin günlük operasyonların planlanması
     ve yönetilmesi.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Hastane
     Yönetimi, Hasta Hakları, Hasta Deneyimi bölümleri tarafından hasta
     memnuniyetinin ölçülmesi, arttırılması ve araştırılması.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>İlaç
     temini.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Randevu
     almanız halinde randevu hakkında sizi haberdar edebilme.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Talep
     etmeniz halinde size en uygun hekime yönlendirme.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Tarafınızca
     gönderilen röntgen, radyolojik belgeler ile ağız içi, diş ve ilgili
     fotoğraf ve videolarınız ile bunlarla bağlantılı diğer verilerinizi
     yönlendirilen hekim ile paylaşma.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Yapay
     zeka kullanılacak olan uygulamamız ve web sitemizden aldığınız hizmetlerin
     gelişmesi amacıyla analiz olarak kullanılması.</span></li>
</ul>

<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;
text-align:justify;text-indent:-.25in;line-height:115%;background:white'><span
lang=TR style='font-size:10.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='color:black'>Alışveriş ve online market
hizmetlerimizi kullanmanız durumunda, tercihleriniz ve ihtiyaçlarınıza göre
seçenekleri kategorize ederek sizin için en uygun hizmetin sunulabilmesi.</span></p>

<p style='margin-left:.5in;text-align:justify;text-indent:-.25in;background:
white'><span lang=TR style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='color:black'>Tercihleriniz ve
ihtiyaçlarınıza göre seçenekleri kategorize ederek sizin için en uygun hizmetin
sunulabilmesi ve medikal - dental ürünler ile kozmetik ve bakım ürünlerinin
geliştirilmesi için yapılacak anket çalışmalarında kullanılması.</span></p>

<p style='margin-left:.5in;text-align:justify;text-indent:-.25in;background:
white'><span lang=TR style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=TR style='color:black'>Hasta Hizmetleri, Mali İşler,
Pazarlama bölümleri tarafından sağlık hizmetlerinizin finansmanı, tetkik,
teşhis ve tedavi giderlerinizin karşılanması, müstehaklık sorgusu kapsamında
özel sigorta şirketler ile talep edilen bilgilerin paylaşılması.</span></p>

<ul type=disc>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Yasal
     ve düzenleyici gereksinimlerin yerine getirilmesi.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kalite,
     Hasta Deneyimi, Bilgi Sistemleri bölümleri tarafından risk yönetimi ve
     kalite geliştirme aktivitelerinin yerine getirilmesi.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Hasta
     Hizmetleri, Mali İşler, Pazarlama bölümleri tarafından hizmetlerimiz
     karşılığında faturalandırma yapılması ve anlaşmalı olan kurumlarla
     ilişkinizin teyit edilmesi.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><b><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif;
     color:windowtext;border:none windowtext 1.0pt;padding:0in'>DentisToday</span></b><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif;
     color:windowtext'> ile anlaşmalı olan kurumlarla ilişkinizi teyit etme.</span></li>
 <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
     lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif;
     color:windowtext'>Doğabilecek uyuşmazlıklarda delil olarak kullanılması
     amaçlarıyla sınırlı olarak işlemektedir.</span></li>
</ul>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>İlgili
mevzuat uyarınca elde edilen ve işlenen Kişisel Verileriniz, </span><b><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif;border:
none windowtext 1.0pt;padding:0in'>DentisToday</span></b><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'> veya </span><b><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif;border:
none windowtext 1.0pt;padding:0in'>DentisToday</span></b><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>’a ait
fiziki arşivler ve/veya bilişim sistemlerine nakledilerek, hem dijital ortamda
hem de fiziki ortamda muhafaza altında tutulabilecektir.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Ayrıca yukarıda
belirtilen kişisel verileriniz, 3359 sayılı Sağlık Hizmetleri Temel Kanunu, 663
sayılı Sağlık Bakanlığı ve Bağlı Kuruluşlarının Teşkilat ve Görevleri Hakkında
Kanun Hükmünde Kararname, Özel Hastaneler Yönetmeliği, Kişisel Sağlık Verilerinin
İşlenmesi ve Mahremiyetinin Korunması Yönetmeliği ve Sağlık Bakanlığı
düzenlemeleri ve sair mevzuat hükümleri çerçevesinde işlenebilecek olup, <b><span
style='border:none windowtext 1.0pt;padding:0in'>DentisToday</span></b>’a
ve/veya onayınız halinde tavsiye edeceğimiz hekimlerimize ait fiziki arşivler
ve bilişim sistemlerine nakledilerek, hem dijital hem de fiziki ortamda
muhafaza altında tutulabilecektir.</span></p>

<p class=MsoListParagraph style='margin-left:14.2pt;text-align:justify;
text-indent:-14.2pt;line-height:normal'><b><span lang=TR style='font-size:12.0pt;
font-family:"Times New Roman",serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kişisel
Verilerin Aktarılması</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kişisel
verileriniz, Kanun, Yönetmelik ve diğer mevzuat kapsamında ve yukarıda yer
verilen amaçlarla <b><span style='border:none windowtext 1.0pt;padding:0in'>DentisToday</span></b>’ın
uygulaması, çalıştığı  hekim ve hastaneler, Sağlık Bakanlığı, bağlı alt
birimleri ve aile hekimliği merkezleri, özel sigorta şirketleri (sağlık,
emeklilik ve hayat sigortası ve benzeri),  </span><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>medikal - dental
ürünler ile kozmetik ve bakım ürünlerinin geliştirilmesi için yapılacak anket
çalışmalarında kullanılmak üzere ilgili kurum ve kuruluşları, </span><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Sosyal
Güvenlik Kurumu, Emniyet Genel Müdürlüğü ve diğer kolluk kuvvetleri, Nüfus
Genel Müdürlüğü, Türkiye Eczacılar Birliği, mahkemeler, tıbbi teşhis için iş
birliği içerisinde olduğumuz yurt içinde bulunan laboratuvarlar, tıp merkezleri
ve sağlık hizmeti sunan üçüncü kişiler, hastanın sevk edildiği veya hastanın
kendisinin başvurduğu sağlık kuruluşu, yetki vermiş olduğunuz temsilcileriniz,
bağlı olduğunuz ve/veya çalışmakta olduğunuz kurum, avukatlar, vergi
danışmanları ve denetçiler de dâhil olmak üzere danışmanlık aldığımız üçüncü
kişiler, düzenleyici ve denetleyici kurumlar ve resmi merciler, hizmetlerinden
faydalandığımız veya işbirliği içerisinde olduğumuz tedarikçilerimiz, destek
hizmet sağlayıcılarımız ve iş ortaklarımız (daha detaylı bilgi için <b><span
style='border:none windowtext 1.0pt;padding:0in'>DentisToday</span></b>’a
yazılı olarak başvurabilirsiniz) ile paylaşılabilecek olup, b</span><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>u kişisel
veriler yukarıda sayılanların dışına başkaca üçüncü kişilerle
paylaşılmamaktadır.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Sağlık verileriniz
yalnızca tedavi olduğunuz veri aktarımına açık rıza verdiğiniz sır saklama
yükümlülüğü mevcut olan hekimlere aktarılmaktadır.</span></p>

<p class=MsoListParagraph style='margin-left:14.2pt;text-align:justify;
text-indent:-14.2pt;line-height:normal'><b><span lang=TR style='font-size:12.0pt;
font-family:"Times New Roman",serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kişisel
Veri Toplamanın Yöntemi ve Hukuki Sebebi</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kişisel
verileriniz, </span><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kanunun
5. maddesinde belirtilen “ilgili kişinin temel hak ve özgürlüklerine zarar
vermemek kaydıyla veri sorumlusunun meşru menfaati için zorunlu olması” hukuki
sebebine dayalı olarak telefon yoluyla, web ortamında, uygulamayı kullanma
aşamasında ve klinik ve diğer hizmetlerimizi kullanırken otomatik ya da otomatik
olmayan yollarla işlenmektedir.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Yukarıda sayılan
kişisel verilerin önemli çoğunluğu tarafınızca web site/mobil uygulamayı
kullanabilmek amacıyla tarafınızca sunulmaktadır. Otomatik olarak toplanan
veriler yalnızca Web Site/Mobil Uygulama kullanım verileridir.</span></p>

<p class=MsoListParagraph style='margin-left:14.2pt;text-align:justify;
text-indent:-14.2pt'><b><span lang=TR style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Çerezler Vasıtasıyla Topladığımız Veriler</span></b></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-size:
12.0pt;line-height:107%;font-family:"Times New Roman",serif'>Sitemizi ziyaret
ettiğinizde ya da hizmetlerimizden yararlandığınızda&nbsp;size daha iyi, hızlı,
güvenli bir deneyim sunabilmemiz için reklam ve pazarlama amaçlarıyla,
alışveriş deneyiminizin takip edilebilmesi ve Site’nin işleyişini yönetebilmek
için çerezler, web işaretçileri gibi teknolojiler kullanılmaktadır.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-size:
12.0pt;line-height:107%;font-family:"Times New Roman",serif'>Bu teknolojileri
kullanımımız ve bunları nasıl kontrol edebileceğiniz hakkında daha ayrıntılı
bilgi için&nbsp;Çerez Politikamızı&nbsp;inceleyiniz.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-size:
12.0pt;line-height:107%;font-family:"Times New Roman",serif'>Ayrıca, popüler
birçok tarayıcı çerezleri kontrol etmenize izin vermektedir. Popüler bazı
tarayıcılar için çerezleri nasıl kontrol edeceğinizi öğrenmek için sizlere
aşağıda bu popüler tarayıcılara ait bağlantılar sunmaktayız. </span></p>

<p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in;
line-height:normal'><span lang=TR style='font-size:12.0pt;font-family:Wingdings'>v<span
style='font:7.0pt "Times New Roman"'> </span></span><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Google
Chrome&nbsp;</span><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><a
href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en"><span
style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:normal'><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in;
line-height:normal'><span class=MsoHyperlink><span lang=TR style='font-size:
12.0pt;font-family:Wingdings;color:windowtext;text-decoration:none'>v<span
style='font:7.0pt "Times New Roman"'> </span></span></span><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Microsoft
Edge&nbsp;</span><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><a
href="https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"><span
style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;text-align:justify;
line-height:normal'><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in;
line-height:normal'><span lang=TR style='font-size:12.0pt;font-family:Wingdings'>v<span
style='font:7.0pt "Times New Roman"'> </span></span><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Mozilla
Firefox&nbsp;</span><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><a
href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"><span
style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:normal'><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in;
line-height:normal'><span lang=TR style='font-size:12.0pt;font-family:Wingdings'>v<span
style='font:7.0pt "Times New Roman"'> </span></span><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Microsoft Internet
Explorer&nbsp;</span><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><a
href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"><span
style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span lang=TR
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in;
line-height:normal'><span lang=TR style='font-size:12.0pt;font-family:Wingdings'>v<span
style='font:7.0pt "Times New Roman"'> </span></span><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Opera&nbsp;</span><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><a
href="https://www.opera.com/help/tutorials/security/privacy/"><span
style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:normal'><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in;
line-height:normal'><span lang=TR style='font-size:12.0pt;font-family:Wingdings'>v<span
style='font:7.0pt "Times New Roman"'> </span></span><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Apple Safari&nbsp;</span><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'><a
href="https://support.apple.com/en-gb/safari"><span style='color:windowtext'>(here)</span></a></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:14.2pt;text-align:justify;
text-indent:-14.2pt;line-height:normal'><b><span lang=TR style='font-size:12.0pt;
font-family:"Times New Roman",serif'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Veri
Güvenliği</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif;border:
none windowtext 1.0pt;padding:0in'>DentisToday</span></b><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>, kişisel
verilerinizi teknik ve idari imkânlarımız dâhilinde titizlikle korumaktadır. Kurumumuz
tarafından alınan güvenlik tedbirleri, teknolojik imkânlar da göz önünde
bulundurularak olası risklere uygun bir düzeyde sağlanmaktadır. Alınan tüm
tedbirlere VERBİS üzerindeki veri güvenliği sekmesinden inceleyebilirsiniz.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kullanıcılarımızdan
elde edilen verilerin tamamı yerel bulut sistemlerde tutulmakta olup, işbu
bulut sistem sağlayıcıları yerel ve uluslararası veri güvenliği standartlarına
uygun olarak hizmetlerini sunmaktadır.</span></p>

<p class=MsoListParagraph style='margin-left:14.2pt;text-align:justify;
text-indent:-14.2pt;line-height:normal'><b><span lang=TR style='font-size:12.0pt;
font-family:"Times New Roman",serif'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Haklarınız</span></b></p>

<p class=MsoNormal><span lang=TR style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>Topladığımız verilere ilişkin olarak
Kanun’un 11. Maddesi uyarınca verilerinizin;</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-bottom:10.0pt;text-align:justify;
text-indent:-.25in;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><i><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>İşlenip işlenmediğini öğrenme,</span></i></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:10.0pt;text-align:
justify;text-indent:-.25in;line-height:115%'><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><i><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>İşleniyorsa bunlara ilişkin bilgi talep
etme,</span></i></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:10.0pt;text-align:
justify;text-indent:-.25in;line-height:115%'><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><i><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>İşlenme amacını ve amacına uygun olarak
kullanılıp kullanılmadığını öğrenme,</span></i></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:10.0pt;text-align:
justify;text-indent:-.25in;line-height:115%'><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><i><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Yurtiçi veya yurtdışında üçüncü kişilerle
paylaşılıyor olması halinde bunların kimler olduğunu bilme,</span></i></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:10.0pt;text-align:
justify;text-indent:-.25in;line-height:115%'><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><i><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Eksik veya yanlış işlenmiş olması halinde
bunların düzeltilmesini isteme,</span></i></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:10.0pt;text-align:
justify;text-indent:-.25in;line-height:115%'><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><i><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>İşlenmesini gerektiren sebeplerin ortadan
kalkması halinde, işlenmiş olunan verilerinizin silinmesini veya yok edilmesini
isteme,</span></i></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:10.0pt;text-align:
justify;text-indent:-.25in;line-height:115%'><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><i><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Talebiniz üzerine verilerinizin silinmesi
veya düzeltilmesi halinde, işlemlerin kişisel verilerin aktarıldığı üçüncü
kişilere bildirilmesini isteme,</span></i></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:10.0pt;text-align:
justify;text-indent:-.25in;line-height:115%'><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><i><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Münhasıran otomatik sistemler vasıtasıyla
analiz edildiği durumlarda aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</span></i></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:10.0pt;text-align:
justify;text-indent:-.25in;line-height:115%'><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><i><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kanuna aykırı olarak işlenmesi sebebiyle
zarara uğramanız hâlinde zararın giderilmesini talep etme,</span></i></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify'><i><span
lang=TR style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></i></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.5in'><span
lang=TR style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>haklarına
sahipsiniz.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.5in'><span
lang=TR style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:14.2pt;text-align:justify;
text-indent:-14.2pt'><b><span lang=TR style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=TR style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>İletişim</span></b></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-size:
12.0pt;line-height:107%;font-family:"Times New Roman",serif'>Yukarıda anılan
hakları, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca
kullanabilirsiniz. Haklarınızı kullanmak istediğinizde bize aşağıdaki yollarla
ulaşabilirsiniz. Kanun kapsamındaki taleplerinizi, mobil uygulamamızdaki “Kişisel
Verilerin Korunması Kanunu Uyarınca Başvuru Formu” nu doldurarak aşağıda
kanallardan bizlere iletebilirsiniz.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-size:
12.0pt;line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 width=619
 style='width:6.45in;border-collapse:collapse;border:none'>
 <tr style='height:31.8pt'>
  <td width=158 valign=top style='width:1.65in;border:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:31.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>BAŞVURU
  YÖNTEMİ</span></b></p>
  </td>
  <td width=124 valign=top style='width:93.35pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt;height:31.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Yazılı
  Olarak Başvuru</span></b></p>
  </td>
  <td width=208 valign=top style='width:156.15pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt;height:31.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Elektronik
  Posta Adresi ile Başvuru</span></b></p>
  </td>
  <td width=128 valign=top style='width:96.1pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt;height:31.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Kayıtlı
  E-Posta (KEP) Kanalı İle Başvuru</span></b></p>
  </td>
 </tr>
 <tr style='height:41.45pt'>
  <td width=158 valign=top style='width:1.65in;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt;height:41.45pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>BAŞVURU
  ADRESİ</span></b></p>
  </td>
  <td width=124 valign=top style='width:93.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:41.45pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Rasimpaşa
  Mahallesi Tayyareci Sami Sok. No: 13/B İç Kapı No:125 Kadıköy/İSTANBUL</span></p>
  </td>
  <td width=208 valign=top style='width:156.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:41.45pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><span lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>info@theaident.com</span></p>
  </td>
  <td width=128 valign=top style='width:96.1pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:41.45pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>aident@hs01.kep.tr</span></p>
  </td>
 </tr>
 <tr style='height:42.0pt'>
  <td width=158 valign=top style='width:1.65in;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt;height:42.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>BAŞVURUDA
  GÖNSTERİLECEK BİLGİ</span></b></p>
  </td>
  <td width=124 valign=top style='width:93.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:42.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Zarfın/tebligatın
  üzerine “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi”
  yazılacaktır</span></p>
  </td>
  <td width=208 valign=top style='width:156.15pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:42.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>DENTİSTODAY’a
  hitaben yazdığınız “word” veya “pdf.” formatındaki bir dosyayı güvenli e-imza
  ile imzalayarak&nbsp;<a href="mailto:info@theaident.com"><span
  style='color:windowtext'>info@theaident.com</span></a> ‘a -posta’nın konu
  kısmına “Kişisel Verilerin Korunması Kanunu Bilgi Talebi” yazarak
  iletebilirsiniz.</span></p>
  </td>
  <td width=128 valign=top style='width:96.1pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt;height:42.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>Güvenli
  elektronik ya da mobil imzalı olarak, kayıtlı elektronik posta adresi veya
  sistemimizde kayıtlı elektronik e-posta adresiniz aracılığıyla, konu kısmına
  “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi”  yazmak
  suretiyle gönderebilirsiniz.</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='text-align:center;line-height:normal'><b><span
lang=TR style='font-size:12.0pt;font-family:"Times New Roman",serif'>Veri
Sorumlusu Künye Bilgileri</span></b></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=158 valign=top style='width:1.65in;border:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='line-height:normal'><b><span lang=TR
  style='font-size:12.0pt;font-family:"Times New Roman",serif'>Ticaret Unvanı</span></b></p>
  </td>
  <td width=456 valign=top style='width:341.8pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='line-height:normal'><span lang=TR style='font-size:
  12.0pt;font-family:"Times New Roman",serif'>AIDENT SAĞLIK VE DİŞ
  TEKNOLOJİLERİ ANONİM ŞİRKETİ</span></p>
  </td>
 </tr>
 <tr>
  <td width=158 valign=top style='width:1.65in;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='line-height:normal'><b><span lang=TR
  style='font-size:12.0pt;font-family:"Times New Roman",serif'>Mersis No</span></b></p>
  </td>
  <td width=456 valign=top style='width:341.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='line-height:normal'><b><span lang=TR
  style='font-size:12.0pt;font-family:"Times New Roman",serif'>0010208562500001</span></b></p>
  </td>
 </tr>
 <tr>
  <td width=158 valign=top style='width:1.65in;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='line-height:normal'><b><span lang=TR
  style='font-size:12.0pt;font-family:"Times New Roman",serif'>Adres</span></b></p>
  </td>
  <td width=456 valign=top style='width:341.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='line-height:normal'><span lang=TR style='font-size:
  12.0pt;font-family:"Times New Roman",serif'>Rasimpaşa Mahallesi Tayyareci
  Sami Sk. No: 13 B/1 Kadıköy/İSTANBUL</span></p>
  </td>
 </tr>
 <tr>
  <td width=158 valign=top style='width:1.65in;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='line-height:normal'><b><span lang=TR
  style='font-size:12.0pt;font-family:"Times New Roman",serif'>İletişim</span></b></p>
  </td>
  <td width=456 valign=top style='width:341.8pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='line-height:normal'><span lang=TR style='font-size:
  12.0pt;font-family:"Times New Roman",serif'>info@theaident.com</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=TR
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:red'>&nbsp;</span></p>

</div>

</body>

</html>

    `;
    
  
  return (
    <div
      style={{
        padding: '20px',
        fontSize: '16px',
        lineHeight: '1.5',
        fontFamily: 'Arial, sans-serif',
      }}
      dangerouslySetInnerHTML={{
        __html: i18n.language === 'en' ? en : tr,
      }}
    >

    </div>
  )
}
