import React from 'react'
import { useTranslation } from 'react-i18next';
import trDocs from '../assets/kvkk-request-tr.pdf';
import enDocs from '../assets/kvkk-request-en.pdf';
import { Box } from '@mui/material';

export default function KvkkRequestForm() {

  const { i18n } = useTranslation();


  return (
   <Box>
    <iframe src={i18n.language === 'tr' ? trDocs : enDocs} title="KVKK Request Form" width="100%" height
    ="1000px" />
   </Box>
  )
}