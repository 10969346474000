import PageView from './PageView';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import { useTranslation } from 'react-i18next';
import phoneIcon from '../assets/phone-icon.svg';
import mailIcon from '../assets/mail-icon.svg';
import { useNavigate } from 'react-router-dom';

const socialIcons = [
  // { icon: YouTubeIcon, name: 'YouTube' },
  // { icon: XIcon, name: 'X' },
  {
    icon: FacebookIcon,
    name: 'Facebook',
    link: 'https://www.facebook.com/people/Dentistoday/100075739643131/'
  },
  {
    icon: InstagramIcon,
    name: 'Instagram',
    link: 'https://www.instagram.com/dentistoday?igsh=d29oeXE3NHV4Mnlh&utm_source=qr'
  },
  {
    icon: LinkedInIcon,
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/company/dentistoday/'
  },
];

export default function Footer() {
  const { t } = useTranslation();
  const nav = useNavigate();

  return (
    <Box id="iletisim" sx={{ backgroundColor: 'white', py: '24px' }}>
      <PageView
        sx={{

        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '24px',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'column', lg: 'row' },
            pb: '24px',
          }}
        >
          <Box
            sx={{
              maxWidth: '470px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '16px',
              }}
            >
              {t('footer.address')}
            </Typography>
            <Typography>
              Rasimpaşa Mah Tayyareci Sami Sk. Derya İş Merkezi No:13/B Kat:3 İç Kapı No:125 Kadıköy/İstanbul/Türkiye
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '16px',
              }}
            >
              {t('footer.phone')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <img src={phoneIcon as string} alt="phone" />
                <Typography>
                  (+90) 533 055 73 84
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <img src={mailIcon} alt="mail" />
                <Typography>
                  info@theaident.com
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '16px',
              }}
            >
              {t('footer.followUs')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}>
              {socialIcons.map((social, index) => (
                <IconButton
                  key={index}
                  aria-label={social.name}
                  onClick={() => window.open(social.link, '_blank')}
                  sx={{ color: 'rgba(71,85,105,1)', gap: '25.28px' }}>
                  <social.icon />
                </IconButton>
              ))}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', md: 'center' },
            gap: '24px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '22.4px',
              color: 'rgba(71,85,105,1)',
            }}>
            @ 2024 Dentistoday. All rights reserved
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { 
                xs: 'repeat(2, 1fr)', 
                sm: 'repeat(3, 1fr)',
                md: 'repeat(4, 1fr)' ,
                lg: 'repeat(7, 1fr)' 
              },
              gap: '4px',
              width: { xs: '100%', md: 'auto' },
            }}>
            {[
              { text: t('footer.privacyPolicy'), path: '/privacy-policy' },
              { text: t('footer.cookiePolicy'), path: '/cookie-policy' },
              { text: t('footer.securityPolicy'), path: '/security-policy' },
              { text: t('footer.clarificationText'), path: '/clarification-text' },
              { text: t('footer.userAgreement'), path: '/user-agreement' },
              { text: t('footer.consentForm'), path: '/consent-form' },
              { text: t('footer.kvkkRequestForm'), path: '/kvkk-request-form' },
            ].map((link, index) => (
              <Button
                key={index}
                variant="text"
                onClick={() => nav(link.path)}
                sx={{
                  textTransform: 'none',
                  color: 'rgba(71,85,105,1)',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    backgroundColor: 'rgba(71,85,105,0.04)',
                  }
                }}>
                {link.text}
              </Button>
            ))}
          </Box>
        </Box>
      </PageView>
    </Box>
  );
}
