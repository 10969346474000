import React from 'react'
import { useTranslation } from 'react-i18next';

export default function UserAgreement() {

    const { i18n } = useTranslation();

    const en = `
    <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"\@Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	font-size:12.0pt;
	font-family:"Arial Unicode MS",sans-serif;
	color:black;}
span.Gvdemetni2
	{mso-style-name:"Gövde metni \(2\)_";
	mso-style-link:"Gövde metni \(2\)1";
	font-family:"Times New Roman",serif;
	font-variant:normal !important;
	font-weight:normal;
	font-style:normal;
	text-decoration:none none;}
p.Gvdemetni21, li.Gvdemetni21, div.Gvdemetni21
	{mso-style-name:"Gövde metni \(2\)1";
	mso-style-link:"Gövde metni \(2\)_";
	margin:0in;
	text-align:center;
	text-indent:-37.0pt;
	line-height:17.05pt;
	background:white;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:black;}
.MsoChpDefault
	{font-size:12.0pt;
	font-family:"Arial Unicode MS",sans-serif;}
 /* Page Definitions */
 @page WordSection1
	{size:595.0pt 842.0pt;
	margin:49.65pt 41.3pt 63.8pt 50.2pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0066CC" vlink=purple style='word-wrap:break-word;
text-justify-trim:punctuation'>

<div class=WordSection1>

<p class=Gvdemetni21 style='margin-top:0in;margin-right:1.0pt;margin-bottom:
6.0pt;margin-left:0in;text-indent:0in;line-height:115%;background:transparent'><b>USER
AGREEMENT OF</b></p>

<p class=Gvdemetni21 style='margin-top:0in;margin-right:1.0pt;margin-bottom:
6.0pt;margin-left:0in;text-indent:0in;line-height:115%;background:transparent'><b>“DENTİSTODAY”
APPLICATION </b></p>

<p class=Gvdemetni21 align=left style='margin-top:0in;margin-right:1.0pt;
margin-bottom:6.0pt;margin-left:1.0in;text-align:left;text-indent:-.25in;
line-height:115%;background:transparent'><b>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Parties</b></p>

<p class=Gvdemetni21 style='margin-top:0in;margin-right:1.0pt;margin-bottom:
6.0pt;margin-left:0in;text-align:justify;text-justify:inter-ideograph;
text-indent:49.65pt;line-height:115%;background:transparent'>This Service Usage
Agreement (the <b>“Agreement”</b>) is conducted by and between real person or
legal entity (<b>the “User”</b>) who downloads <b>“DentisToday</b>” mobile
application (the “Application”) and by creating a membership on such Website
and “<span lang=TR>AIDENT SAĞLIK VE DİŞ TEKNOLOJİLERİ ANONİM ŞİRKETİ</span>”
(the <b>“Company”)</b> having its registered address at Rasimpaşa Mahallesi
Tayyareci Sami Sok. No: 13/B İç Kapı No:125 Kadıköy/Istanbul and the articles
of the Agreement shall be deemed to be agreed by the <b>User</b> and be
effective when the <b>User </b>downloads the <b>Application</b> to her/her/its
mobile device or after the completion of the membership transaction on the Application.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:56.7pt;line-height:115%;background:transparent'>The
User and the Company shall be referred, when necessary, solely as the “Party”
and jointly as the “Parties” in this Agreement</p>

<p class=Gvdemetni21 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;
margin-left:1.0in;text-align:justify;text-justify:inter-ideograph;text-indent:
-.25in;line-height:115%;background:transparent'><b>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Definition of Service - Subject of the Agreement</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:56.7pt;line-height:115%;background:transparent'>Application
or Website is a free application that the Users of the Website follow up their personal
information, Contact information, location information, shopping history,
appointment information, health insurance policy information, policy plan,
their private discounts, information on their health history, address
information of contracted institutions and pharmacies within the scope of
insurance policies, and also their daily activities according to the number of
steps they take in a movement-oriented manner.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:49.65pt;line-height:115%;background:transparent'>This
Agreement regulates the Terms of Use of the <b>Application</b> and <b>Website;</b>
rights and obligations of the <b>User</b> and <b>the Company</b>.</p>

<p class=Gvdemetni21 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;
margin-left:1.0in;text-align:justify;text-justify:inter-ideograph;text-indent:
-.25in;line-height:115%'><b>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Definitions</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:49.65pt;line-height:115%'><b>Patient: </b>It
defines the person who logs into the Platform, who benefits from the
pre-diagnosis service with the support of artificial intelligence as a result
of intraoral photographs, dental radiological images uploaded to the system
with his/her own consent and anamnesis filled with completely accurate
information and reaches the User upon request.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:49.65pt;line-height:115%'><b>Platform/Mobile
Application: </b>It refers to the mobile application named “Dentistoday”, all
legal rights of which belong to DentisToday, which can be put into service on
the Android Operating System and IOS operating system of DentisToday, where
Users can benefit from the pre-diagnosis service with artificial intelligence
supported radiological identification, which includes their personal
information, contact information, and intellectual rights belonging to
DentisToday, and enables them to communicate with the Patient in the ongoing
process.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:49.65pt;line-height:115%'><b>User/Users: </b>Refers
to real or legal persons and their representatives who use the Mobile
Application by accepting DentisToday's User Agreement and Terms, privacy
notices, explicit consent text by becoming a member.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:49.65pt;line-height:115%'><b>Personal Data: </b>Refers
to any information relating to an identified or identifiable natural person.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:49.65pt;line-height:115%;background:transparent'><b>KVKK:
</b>Law No. 6698 on the Protection of Personal Data.</p>

<p class=Gvdemetni21 style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;
margin-left:49.65pt;text-align:justify;text-justify:inter-ideograph;line-height:
115%'><a name=bookmark0>            <b>4. Rights And Obligations Of The Parties</b></a></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>4.1.</b> The User
shall have the right to use this Platform by entering or visiting the Mobile
Application. The User will be able to use the Mobile Application by confirming
his/her presence in the system using his/her Mobile Phone Number or E-Mail
address. </p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>4.2.</b> In case
the User is a real person, he/she accepts, declares and undertakes that he/she
is a real person who is over 18 years of age, has the rights and capacity to
act, and is allowed to make transactions within the framework of Turkish laws
in order to make transactions on the Mobile Application of DentisToday. In
addition, parents have the right to create a membership for their children
under the age of 18, at their own risk.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:14.15pt;line-height:115%'>       <b>4.3.</b> The
User always has the right to terminate the membership of the Mobile Application.
The User's termination of the membership does not automatically mean the
cancellation of sending commercial electronic messages, sending notifications
and other permissions obtained by DentisToday. In such a case, the User must
terminate the permissions given separately and individually.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.4.</b> By accepting
the provisions of this Agreement, the User accepts all kinds of transactions
described by DentisToday regarding the use and services included in the Mobile
Application and placed in the Mobile Application. The User accepts, declares
and undertakes that he/she will act in accordance with all kinds of matters
specified in the aforementioned statements, use this Platform in accordance
with the terms and conditions in this Agreement, otherwise the Company will
have the right to stop the use of the Platform. </p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.5.</b> The User is
aware that the general purpose of the Mobile Application is to utilize the
radiological identification and preliminary diagnosis service with the support
of artificial intelligence provided by DentisToday, all intellectual rights of
which belong to DentisToday, and to communicate with the Patient if necessary
in the ongoing process.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.6.</b> The User
accepts, declares and undertakes that he/she will enter the information
requested from him/her at the entrance to the Mobile Application in full and in
accordance with the truth, that he/she will update the information when there
is a change in this information, that he/she will use the Mobile Application
for himself/herself by entering his/her own information correctly, and that
he/she will be responsible for the consequences that may arise due to
incorrect, misleading, incomplete and/or untrue information. DentisToday does
not make any commitment regarding the diagnosis-diagnosis-treatment processes
of Patients and Physicians using the application. All legal, criminal and
administrative responsibility for the decisions and practices taken at this
point belongs to those concerned.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.7.</b> The User uses
the Mobile Application knowing that the Mobile Application is prepared as a
recommendation for an average person who is assumed to have no medical
condition, based on the general and normal health conditions of the Patient, or
is a preparatory stage for preliminary diagnosis, does not contain any personalized
content in any way, and does not provide a medical diagnosis and treatment. </p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.3pt;line-height:115%'>     <b>4.8.</b> The User
has the right to request DentisToday to delete the information entered while
using the Mobile Application at any time. The User has the right to withdraw
the approvals given under this Agreement and can always apply to DentisToday in
this regard.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0in;line-height:115%;background:transparent'>            <b>4.9.</b>
The User declares and undertakes that he/she accepts that he/she will act in
accordance with the provisions of this Agreement, ethical rules and all applicable
legislation in the transactions he/she has carried out through the Mobile
Application or in his/her meetings with DentisToday officials. As a result of
the User acting in violation of the aforementioned rules, DentisToday may take
measures such as canceling, stopping, suspending the User's account without any
notice. The authority to make the necessary legal and technical evaluation in
this regard belongs exclusively to DentisToday and the User accepts this
situation. The legal and criminal liability regarding the transactions and
negotiations made by the User through the Platform belongs to the User. The
User is responsible for all damages arising from the cancellation, suspension
or suspension of his/her account in accordance with this article.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.10.</b> The User is
responsible for the security of his/her account and must protect the password
and/or other information used to access his/her account in a secure manner. The
User is responsible for the accuracy of the information he/she has provided
while registering to the Mobile Application, for the confidentiality of the
information he/she has provided while registering, and for the protection of
his/her password and login information as long as the membership continues.
DentisToday cannot be held responsible for any cyber-attack and all kinds of
theft crimes that may occur in the User's accounts or DentisToday's Platform
due to DentisToday's server, internet provider or any systemic reason. The User
accepts, declares and undertakes that he/she cannot make any claim under any
name from DentisToday for the damages arising as a result of these situations
and irrevocably releases DentisToday.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.11.</b> DentisToday
may share the User's information with the competent public authorities if
requested by the competent public authorities in accordance with the applicable
legislation.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.12.</b>
DentisToday's failure to exercise its rights and powers arising from this
Agreement at the time of the birth of these rights and powers does not mean
that it waives them, even implicitly. DentisToday is entitled to exercise such
rights and powers at any time in accordance with the law.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.13.</b> The User may
only have a single account to benefit from DentisToday's products and services.
If DentisToday determines that the same person has more than one account,
DentisToday may take measures such as canceling, stopping, suspending all
accounts of the User without any notice. Due to these measures, the User
accepts, declares and undertakes that DentisToday has no responsibility and
irrevocably releases DentisToday. The legal and criminal responsibility for the
transactions and negotiations made by the User through the Platform belongs to
the User.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.14.</b> The User is
responsible for the security of his/her account information and password and
the transactions carried out through his/her account. Transactions made from
the User's account are deemed to have been carried out by the User. Therefore,
the User is responsible for the security of his/her account. The User accepts,
declares and undertakes that the transactions carried out through his/her
account are made by him/her, that the responsibility arising from these
transactions is in advance, that he/she cannot assert any defenses and/or
objections that the transactions realized in his/her account were not made by
him/her, and that he/she cannot avoid his/her obligations based on such
defenses and/or objections.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.15.</b> The User is
obliged to keep his/her personal information and contact information that
he/she notifies to DentisToday via the Platform up to date. In the event of a
change in such information of the User, the User is obliged to notify
DentisToday immediately and DentisToday shall not be held liable for any
damages that may occur in case of failure to notify. DentisToday shall be
deemed to have fulfilled its notification obligations as a service provider by
providing all information through the communication channels registered in the
system.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.16.</b> DentisToday
provides services with a company policy in accordance with the fundamental
right of individuals to protect personal data. DentisToday will take all care
to ensure the security of the User's data and account. DentisToday, which will
ensure the security of the User's account, will not be responsible for any
damages arising from the User's own fault or any damages that occur outside
DentisToday. The User accepts, declares and undertakes that he/she irrevocably
releases DentisToday in these cases.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0in;line-height:115%;background:transparent'>            <b>4.18.</b>
The User must comply with the provisions of the intellectual property rights
section of this Agreement. The User's violation of these provisions is the
reason for the unilateral and justified termination of the Agreement and all
legal and criminal liability that may arise belongs to the User. In case of
victimization arising as a result of the User allowing third parties to use
his/her account, the responsibility belongs to the User and DentisToday will be
able to claim all damages from the User and related persons. The User declares
that he/she has read and accepted this provision.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>4.19.</b> The User
may not engage in activities that will create unreasonable loads on
DentisToday's system and damage its technical functioning. It cannot engage in
activities such as data mining data scanning, spider, web crawler and trading
with robots through the Platform. It cannot install malicious software that
damages the content of the platform. It will not use the platform against
morality and law and cannot use it to create any guide, database and record
keeping tool. The user accepts and declares that he/she will not enter the
account with the robot method and will not engage in any damaging software or
other activity on the Platform and that he/she has taken measures accordingly.
Due to violations of this provision, DentisToday reserves its rights to claim,
sue or pursue under this Agreement and the legislation.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.20.</b> The User may
request the closure of his/her account opened on the Platform at any time
without any justification. The User may only submit the account closure request
to DentisToday and his/her account will be closed within 30 days after
submitting the request, provided that there is no legal obstacle. DentisToday
may keep the personal data of the User whose account is closed in accordance
with the legislation or the interests of the Parties for the period specified
in the legislation and company data policy.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>4.21.</b> DentisToday
has the right to use and make available the personal information provided by
the Users and stored in the database to convert the User's use of the Mobile
Application, visit information (including, but not limited to, visit duration,
time, pages viewed) into statistical information, to use it in advertising and
marketing, to inform about new campaigns and activities, to determine the
general trends of users, to enrich the content and services of the Mobile
Application.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0in;line-height:115%;background:transparent'>            <b>4.22.</b>
DentisToday is not responsible for the content of the sites and/or other
applications that it promotes/will promote with banners and links within the
Mobile Application.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0in;line-height:115%;background:transparent'>&nbsp;</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>5. Protection of
Personal Data</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>5.1.</b> DentisToday
acts in accordance with the Law No. 6698 on the Protection of Personal Data
regarding the processing of personal data obtained from Users. DentisToday
Personal Data Protection Policy, Cookie Policy, Clarification Text and Explicit
Consent Statement, which can be accessed through the Platform, are an integral
part of this Agreement. Despite all precautions and legal measures taken,
DentisToday cannot be held responsible for the use, theft or processing of
personal data outside the knowledge and internal control of DentisToday for any
reason.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>5.2.</b> Upon
termination of the contract with DentisToday for any reason whatsoever, the
User shall immediately destroy or anonymize the personal data delivered to
him/her by DentisToday to ensure the fulfillment of the purpose of the contract
in accordance with the procedures stipulated by the relevant legislation and
regulations to be published and entered into force even after the signature of
this Agreement.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'>&nbsp;</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>5.3.</b> Pursuant to
the agreements concluded between DentisToday and the User, in any case where
the data belonging to a person is to be transferred to DentisToday by the User,
the User undertakes to inform the relevant data subjects in accordance with the
legislation in force and within the scope of the legislation and to obtain the
necessary permissions and approvals from the relevant persons in the content
and format in accordance with the legislation and that the information minutes
and consent forms will be transferred to DentisToday together with the personal
data.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>5.4.</b> The User
agrees, declares and undertakes that in the event that personal data is
transferred to DentisToday or data is transferred to him/her by DentisToday,
he/she will apply the most up-to-date and secure process regarding the transfer
and storage, take all possible security measures, and take all necessary
measures to prevent physical and digital unauthorized access or transfer.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>5.5.</b> The User
agrees and undertakes that in the event of any unauthorized access or improper
transfer of personal data transferred by DentisToday, he/she will immediately
notify DentisToday of this situation, will cooperate with DentisToday to
minimize the damages that may arise from this situation, to eliminate the
damage arising from this situation, and to provide all kinds of information,
documents and support requested by DentisToday without delay.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>6. Intellectual
Property Rights</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>6.1.</b> All copyright
rights in the Platform belong to DentisToday. DentisToday expressly reserves
all its rights under this Agreement, including the right to discontinue, update
or discontinue the Platform, to change the Platform fees, features,
specifications, technical specifications, capacity, functionality and general
availability or characteristics. DentisToday owns the intellectual and industrial
property rights of every product on the Platform, including but not limited to
the logo, idea, text, design, domain name, software codes, contents of
interfaces, drawings, algorithms, videos on the Platform, any information
including know-how and show-how and all materials (“Materials”) it uses,
including business models and business method. DentisToday does not allow the
copying, reproduction, processing, reverse engineering of the services, pages
and services, Materials for which it owns the intellectual and industrial
property rights. The User accepts, declares and undertakes not to act contrary
to this provision. The intellectual and industrial property rights contained in
this provision are unlimited and DentisToday has not waived any of them. As
soon as DentisToday detects a violation of intellectual and industrial property
rights, DentisToday may take all kinds of legal remedies, especially measures
related to User Accounts, to the infringing User and persons associated with
the User.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0in;line-height:115%;background:transparent'>            <b>6.2.</b>
DentisToday may use the User's business name, physician name information and
logo in printed and digital media for reference purposes. DentisToday
prioritizes the protection of the User's commercial reputation in referencing
transactions.            </p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>7. Sanctions</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>7.1.</b> The User
undertakes to use the Platform in accordance with the laws and regulations of
the Republic of Turkey. All legal and criminal liability that may arise due to
any actions and transactions carried out through the User's account on the
Platform belongs to the User. DentisToday shall have no legal or criminal
liability whatsoever for the User's actions contrary to the laws and
regulations.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>7.2.</b> The User may
only use the Platform for lawful purposes. In the event that it is detected
that the Platform is used by the User for unlawful purposes or made available
to another third party, DentisToday has the right and authority to share all
information, data and transaction records of the User with the competent
authorities. This situation cannot be considered as a breach of confidentiality
and no responsibility can be attributed to DentisToday for this sharing.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>7.3.</b> In the event
that an unlawful transaction is detected in the User's account, DentisToday has
the right to resort to any legal remedy and to take all necessary
administrative and security measures such as freezing the account, permanent or
temporary deletion of the account or termination of the Agreement. </p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>7.4.</b> In verbal,
written and video communication with DentisToday, the User may not engage in
behaviors and discourses that are contrary to the general rules of morality and
honesty, that contain insults and disrespect, and that abuse DentisToday's
goodwill. In the event that the User performs the aforementioned actions,
DentisToday reserves the right to freeze the User's account, to temporarily or
permanently block the User's access to the account and to close the account to
transactions within the scope of the activity and reserves the right to resort
to any legal remedy. DentisToday cannot be held legally and criminally liable
due to the application of this article.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>7.5.</b> The User may
not make any posts that may damage the commercial reputation of DentisToday,
except for complaints in the basic application through written and visual media
and social media. Otherwise, DentisToday may suspend the User's access to the
platform and delete the User's account, without prejudice to all legal rights
arising from the legislation.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0in;line-height:115%'>            <b>8. Privacy</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>8.1.</b> If this
Agreement is accepted and approved by the User, DentisToday undertakes to keep
all kinds of information and data belonging to the User confidential and to
take all measures for confidentiality. However, information and documents
requested by the competent authorities of the public institutions of the
Republic of Turkey may be submitted to the competent authorities within the
scope of laws, Decree Laws and all other legislation. Although this does not
constitute a breach of confidentiality, DentisToday cannot be considered
responsible for any damages that may arise.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>8.2. </b>The parties
have mutually accepted the rights and obligations arising under the Law No.
6698 on the Protection of Personal Data and hereby agree and undertake to act
in accordance with the said law.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>8.3.</b> DentisToday
may link to other sites, redirect to third parties' sites or publish their
forms within the Mobile Application within the scope of its cooperation with
third parties. Likewise, within the scope of the agreement it has made with
third parties, it may publish its own advertisements on different platforms and
publish links and forms. In this case, DentisToday cannot be held responsible
for the privacy and cookie policy and any other content of other sites accessed
through third party links. Cooperation with other companies or organizations
does not mean that DentisToday has any connection with these companies or
organizations. The User is responsible for his/her interaction with any
non-site or non-application link on the Site or Application.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>8.4.</b> DentisToday,
in order to fulfill or implement the requirements of the Agreement signed
between the User and the User, in order to protect the rights or security of
the User, information about the Users within the scope of an investigation or
investigation duly conducted by competent judicial or administrative
authorities, In the event that documents or data are requested, DentisToday may
share all kinds of information, documents and data with third parties by going
beyond the confidentiality provisions of this Agreement in cases where it is
required to comply with the Law, Decree Law, Regulation and all kinds of
legislation newly issued by the competent institutions and authorities or
currently in force.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>8.5.</b> All
information that the User learns/learns, obtains and will obtain; any written
or verbal information, any information developed by DentisToday, any know-how,
know-how, show-how, method, method, technique, development or an idea,
application, method, invention, design, model, work, any information on
information, documents, documentation, processes and the like provided to the
User is confidential information. For detailed privacy, cookie, information
security policy, other information and KVKK questions, please review the KVKK
and other relevant texts in the Mobile Application.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>9. Force Majeure</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        In the country where
the devices and systems where the Platform will be used are installed,
earthquakes, strikes, bad weather conditions, epidemics, war whether declared
or not, embargo, uprising, natural disasters, government decisions, problems in
the internet infrastructure and / or its complete shutdown, failure of the
telecommunications infrastructure to operate in the technical adequacy of the
foreseen ordinary services to use the Application, situations are force majeure
to the extent that they directly affect the terms of the Agreement. During the
period of force majeure, the responsibilities and duties of the Parties shall
be suspended and no sanctions shall be imposed or the Parties shall not claim
damages from each other for failure to fulfill the provisions of the Agreement
during this period. If this force majeure situation lasts for more than 1 (one)
month, the Parties shall have the right to terminate this Agreement without any
further liability.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>10.Evidence</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0in;line-height:115%;background:transparent'>            In
any dispute between the parties, the books, documents and electronic media
records and all kinds of data kept by DentisToday, including customer
transactions, shall be evidence. Data kept on DentisToday's servers, e-archive
records and all kinds of electronic information are binding and exclusive
evidence. The User is aware that his/her phone call with DentisToday customer
experience team or any other authorized personnel may be recorded and these
recordings may be evidence in any dispute. The User agrees that this provision
is an evidential contract in accordance with Article 193 of the Code of Law No.
6100. It accepts, declares and undertakes that it is an evidence contract in
accordance with Article 193 of the Law No. 6100.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:35.45pt;line-height:115%'><b>11. Severability of
The Agreement</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        If at any time and
for any reason whatsoever any provision, clause or condition of this Agreement
is held invalid or unenforceable, in whole or in part, by any official or
competent authority, the remainder of this Agreement shall not be affected
thereby and the invalidated portion shall be construed as if it had been
previously omitted. In such event, the Parties shall endeavor to make such
amendments as may be legally acceptable and which provide alternatives as close
as possible to the relevant provision of this Agreement.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>12. Amendment of
The Agreement</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        DentisToday has the
right to unilaterally change and update this Agreement and the terms and
conditions in all legal texts on the Platform by publishing them on the
Platform without the need for notice. The changes made shall take effect and
result from the moment they are published in the Mobile Application.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>13. Termination of
The Contract</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        The Parties may
unilaterally terminate this Agreement between them with prior notice. Also;</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        - In the event that
the User acts contrary to the provisions of this Agreement or does not act in
accordance with the obligations accepted, declared and undertaken under the
provisions of the Agreement</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        - In the event that
the User defaults in the payment of debt obligations incurred as a result of
using the products and services offered by DentisToday</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        - In case the
information and documents provided by the User within the framework of this
Agreement are incorrect,</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        - The User is in a
state of bankruptcy or insolvency or there are sufficient indications that it
will be in the near future,</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        - In case of
realization of other reasons for termination stated in this Agreement, the
Agreement may be terminated unilaterally.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>14. Transfer of
The Contract</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        The User has no right
to transfer and assign its rights and receivables, debts and obligations
arising from this Agreement to third parties under any circumstances and under
any circumstances. </p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>15. Applicable Law</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        The provisions of
this Agreement shall be governed by the law of the Republic of Turkey. Istanbul
Courts are exclusively authorized in any dispute arising out of or in
connection with this Agreement.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        <b>16. Acceptance and
Effectiveness</b></p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        This Agreement starts
when the User becomes a member of DentisToday's Platform electronically.While
becoming a member, the User accepts, declares and undertakes that he / she has
read all the articles and provisions in this Agreement separately and agrees in
every sentence of all articles and provisions of the Agreement.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:115%'>                        This User Agreement
consists of 16 (sixteen) main articles and has entered into force at the moment
of approval by the User by reading each provision separately by the User and by
being approved electronically by being understood in its entirety.</p>

<p class=Gvdemetni21 style='margin-bottom:6.0pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0in;line-height:115%;background:transparent'>&nbsp;</p>

</div>

</body>

</html>

    `;
    const tr = `
    <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"DengXian Light";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:"Segoe UI Symbol";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:"\@DengXian Light";}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:16.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#2F5496;
	font-weight:normal;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Calibri Light",sans-serif;
	color:#2F5496;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=TR
style='font-family:"Times New Roman",serif'>DENTISTODAY KULLANICI SÖZLEŞMESİ</span></b></p>

<h1 style='margin-top:6.0pt;margin-right:-17.0pt;margin-bottom:2.0pt;
margin-left:0in;text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>1.
TARAFLAR</span></b></h1>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>1.1</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> İşbu Dentistoday Kullanıcı
Sözleşmesi ve Kullanıcı Şartları’nın (Bundan sonra “Sözleşme” olarak
anılacaktır) bir tarafta <i>DentisToday</i> mobil uygulamasının sahibi olan <i>Rasimpaşa
Mahallesi Tayyareci Sami Sok. No: 13 B/1 Kadıköy, İstanbul</i> adresinde mukim <b>AIDENT
SAĞLIK VE DİŞ TEKNOLOJİLERİ ANONİM ŞİRKETİ</b> (Bundan sonra “<b>DentisToday</b>”
olarak anılacaktır.) ile&nbsp;<b>DentisToday </b>mobil uygulamasına (bundan
sonra <b>“Platform”</b> olarak anılacaktır.) Sözleşme’deki şartları kabul
ederek üye olan kullanıcı (Bundan sonra “<b>Kullanıcı</b>” olarak anılacaktır)
arasında imzalanmıştır.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>1.2.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b> ve Kullanıcı,
ayrı ayrı “<b>Taraf</b>”, birlikte “<b>Taraflar</b>” olarak anılacaktır.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>&nbsp;</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>2. SÖZLEŞME’NİN
KONUSU VE AMACI</span></b></h1>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>2.1.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> İşbu Sözleşme’nin konusunu, <b>DentisToday</b>
ile Kullanıcı arasında kurulan Sözleşme kapsamında, Hasta’ların kendi rızası
ile sisteme yüklediği ağız içi fotoğraflarının, dental radyolojik
görüntülerinin ve doğru bilgiler ile doldurulan dental form sonucunda yapay zekâ
desteğiyle ön tanı hizmetinin ve  Kulanıcılar’ın da ön tanı hizmetinin ardından
Hasta ile iletişim kurmasının, Hasta’nın Kullanıcı’ya ulaşmasının takdiri <b>DentisToday’
</b>e ait olmak üzere ücretsiz ya da <b>DentisToday tarafından </b>belirlenecek
bir ücret karşılığında <b>DentisToday</b> tarafından Kullanıcı’ya
sağlanmasıdır.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>2.2.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> İşbu Sözleşme Taraflar’ın
Sözleşme’de açıklanan hizmetlere yönelik birbirlerine karşı olan hak ve
yükümlülüklerini belirlemek amacıyla akdedilmiştir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>2.3.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> İşbu Sözleşme Taraflar arasında
Kullanıcı’nın Platform’a üye olması anında akdedilmiştir. Kullanıcı, Platform’a
üye olurken işbu Sözleşme’nin tüm hükümlerini madde madde okuyarak kabul
ettiğini kabul ve beyan eder.</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>3.
TANIMLAR</span></b></h1>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>Hasta: </span></b><span lang=TR
style='font-family:"Times New Roman",serif'>Platforma’a giriş yapan kendi
rızası ile sisteme yüklediği ağız içi fotoğraflarının, dental radyolojik
görüntülerinin ve tamamen doğru bilgiler ile doldurulan anamnezler sonucunda
yapay zekâ desteğiyle ön tanı hizmetinden yararlanan ve isteği halinde
Kullanıcı’ya ulaşan kişiyi tanımlamaktadır.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>Platform/Mobil Uygulama:</span></b><span
lang=TR style='font-family:"Times New Roman",serif'> Tüm yasal hakları <b>DentisToday</b>’e
ait olan, “Dentistoday” isimli, Kullanıcı’ların sahip oldukları kişisel
bilgilerini, iletişim bilgilerini içeren fikri hakları <b>DentisToday</b>’e ait
olan yapay zeka destekli radyolojik tanımlama ile ön tanı hizmetinden
yararlanabilecekleri ve devam eden süreçte Hasta ile iletişim kurabilmelerini
sağlayan <b>DentisToday</b>’e ait Android İşletim Sistemi ile IOS işletim
sistemi üzerinden hizmete sunulabilen mobil uygulamayı ifade etmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>Kullanıcı/Kullanıcılar:</span></b><span
lang=TR style='font-family:"Times New Roman",serif'> Üye olmak suretiyle <b>DentisToday</b>’in
Kullanıcı Sözleşmesi ve Şartlarını, gizlilik bildirimlerini, açık rıza metnini
kabul ederek Mobil Uygulama’yı kullanan gerçek veya tüzel kişiler ile
temsilcilerini ifade eder.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>Kişisel Veri:</span></b><span
lang=TR style='font-family:"Times New Roman",serif'> Kimliği belirli veya
belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi ifade eder.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>KVKK:</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> 6698 sayılı Kişisel Verilerin
Korunması Kanunu’nu ifade eder.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>&nbsp;</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>4.
TARAFLAR’IN HAK VE YÜKÜMLÜLÜKLERİ</span></b></h1>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.1.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, Mobil Uygulama’ya
girerek veya Mobil Uygulama’yı ziyaret ederek işbu Platform’u kullanma hakkına
sahip olacaktır. Kullanıcı Mobil Telefon Numarasını veya E-Posta adresini
kullanarak sistemdeki varlığını onaylatmak suretiyle Mobil Uygulama’yı kullanabilecektir.
</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.2.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı gerçek kişi olması
halinde, <b>DentisToday’e</b> ait Mobil Uygulama’da işlem yapabilmek için 18
yaşından büyük, hak ve fiil ehliyetini haiz, Türk yasaları çerçevesinde işlem
yapabilmesine cevaz verilen gerçek kişi olduğunu kabul, beyan ve taahhüt eder. Bunun
yanında veliler 18 yaşından küçük çocukları için her türlü sorumlulukları
kendilerine ait olmak üzere üyelik oluşturma hakkına sahiptir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.3.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı’nın, Mobil Uygulama üyeliğini
sonlandırabilme hakkı her zaman vardır. Kullanıcı’nın üyeliği sonlandırması,
ticari elektronik ileti gönderimi, bildirim gönderme ve <b>DentisToday’in </b> almış
olduğu diğer izinlerin otomatik olarak iptali anlamına gelmemektedir. Böyle bir
durumda Kullanıcı’nın vermiş olduğu izinleri ayrıca ve tek tek sonlandırması
gerekmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.4.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, işbu Sözleşme
hükümlerini kabul etmekle, Mobil Uygulama içinde yer alan kullanıma ve
hizmetlere ilişkin <b>DentisToday</b> tarafından açıklanan, Mobil Uygulama’da konulan
her türlü işlemi de kabul etmiş olmaktadır. Kullanıcı, bahsi geçen beyanlarda
belirtilen her türlü hususa uygun olarak davranacağını, işbu Platform’u işbu Sözleşme’deki
şart ve koşullara uygun olarak kullanacağını, aksi durumda Şirket’in Platform
kullanımını durdurma hakkına sahip olacağını kabul, beyan ve taahhüt eder. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.5.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, Mobil Uygulama’nın
genel amacının tüm fikri hakları <b>DentisToday’e</b> ait olan ve <b>DentisToday</b>
tarafından sağlanan yapay zekâ desteği ile radyolojik tanımlama ve ön tanı hizmetinden
yararlanılması ve devam eden süreçte gerekirse Hasta ile iletişim sağlanması olduğunu
bilmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.6. </span></b><span lang=TR
style='font-family:"Times New Roman",serif'>Kullanıcı, Mobil Uygulama’ya
girişte kendisinden istenilen bilgileri tam ve gerçeğe uygun olarak gireceğini,
bu bilgilerde değişiklik olduğunda bilgileri güncelleyeceğini, Mobil
Uygulama’ya bizzat kendisi için kendi bilgilerini doğru şekilde girmiş olarak
kullanacağını, bilgilerini yanlış, yanıltıcı, eksik ve/veya gerçeğe aykırı
olarak bildirmesinden dolayı doğabilecek olan sonuçlardan sorumlu olacağını
kabul, beyan ve taahhüt eder. <b>DentisToday, </b>uygulamayı kullanan Hasta ve
Hekim’ lerin tanı-teşhis-tedavi süreçlerine dair bir taahhütte bulunmamaktadır.
Bu noktada alınan kararlar ve uygulamalara dair tüm hukuki, cezai ve idari
sorumluluk ilgililerine aittir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.8.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, Mobil Uygulama’nın Hasta’nın
genel ve normal sağlık durumları esas alınarak, tıbbi rahatsızlığı olmadığı
varsayılan ortalama bir kişi için tavsiye niteliğinde hazırlandığını veya ön
tanı için hazırlık aşaması olduğunu, hiçbir şekilde kişiye özel bir içerik
içermediğini, tıbbi bir tanı ve tedavi sağlamadığını bilerek Mobil Uygulama’yı
kullanmaktadır. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.9.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, Mobil Uygulama’yı kullanırken
girdiği bilgilerin silinmesini her zaman <b>DentisToday’</b> talep edebilme
hakkına sahiptir. Kullanıcı işbu Sözleşme kapsamında verdiği onayları geri alma
hakkına sahip olup bu konuda her zaman <b>DentisToday’ </b>e başvuruda
bulunabilir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.10.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, Mobil Uygulama üzerinden
gerçekleştirmiş olduğu işlemlerde veya <b>DentisToday</b> yetkilileri ile
yaptığı görüşmelerde, işbu Sözleşmenin hükümlerine, ahlak kurallarına,
yürürlükte olan tüm mevzuata uygun olarak hareket edeceğini kabul ettiğini
beyan ve taahhüt eder. Kullanıcı’nın bahsi geçen kurallara aykırı şekilde
hareket etmesi sonucu <b>DentisToday</b>, Kullanıcı’ya ait hesabı hiçbir
bildirimde bulunmaksızın, iptal etme, durdurma, askıya alma gibi tedbirleri
alabilir. Bu konuda gerekli hukuki ve teknik değerlendirmeyi yapma yetkisi
münhasıran <b>DentisToday</b>’e aittir ve Kullanıcı bu durumu kabul etmektedir.
Kullanıcı’nın Platform üzerinden yaptığı işlem ve görüşmelere ilişkin hukuki ve
cezai sorumluluk kendisine aittir. Kullanıcı, hesabının bu madde hükmünce iptal
edilmesi, durdurulması veya askıya alınması halince doğacak bütün zararlarından
kendisi sorumludur.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.11.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı kendi hesabının
güvenliğinden sorumlu olup hesabına erişim için kullandığı şifre ve/veya diğer
bilgileri güvenli bir şekilde korumak zorundadır. Mobil Uygulama’ya üye olurken
vermiş olduğu bilgilerin doğruluğundan, 3. kişiler tarafından ele geçirilmemesinden,
kaydolurken vermiş olduğu bilgilerin gizliliğinden ve üyelik devam ettiği
sürece şifre ve giriş bilgilerinin korumasından Kullanıcı sorumludur. <b>DentisToday’e</b>
ait sunucu, internet sağlayıcısı ya da herhangi bir sistemsel sebeple
Kullanıcı’nın hesaplarında veya <b>DentisToday’in</b> Platform’unda meydana
gelebilecek bir siber saldırı ve her türlü hırsızlık suçlarında <b>DentisToday</b>
sorumluluğuna gidilemez. Kullanıcı, bu durumlar sonucu doğacak zararlar için <b>DentisToday’
den</b> hiçbir ad altında talepte bulunamayacağını, <b>DentisToday’i</b> gayri
kabili rücu olarak ibra ettiğini kabul, beyan ve taahhüt eder.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.12.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b> yürürlükteki
mevzuat gereğince yetkili kamu otoritelerinin talep etmesi halinde
Kullanıcı’nın bilgilerini yetkili kamu otoritelerinin talebi doğrultusunda söz
konusu idari makamlarla paylaşabilecektir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.13.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b>, işbu Sözleşme’den
doğan hak ve yetkilerini bu hak ve yetkilerin doğumu anında kullanmaması zımnen
de olsa feragat ettiği anlamına gelmemektedir. <b>DentisToday</b>, yasalara uygun
olarak söz konusu hak ve yetkilerini dilediği zaman kullanmaya ehildir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.14.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, <b>DentisToday’in</b>
ürün ve hizmetlerinden faydalanmak amacıyla sadece tek bir hesaba sahip
olabilir. <b>DentisToday</b>, aynı kişinin birden fazla hesabı olduğunu tespit
etmesi halinde, Kullanıcıya ait tüm hesapları herhangi bir bildirimde
bulunmaksızın iptal etme, durdurma, askıya alma gibi tedbirleri alabilir. Bu
tedbirler nedeniyle Kullanıcı <b>DentisToday’in</b> herhangi bir sorumluluğu
olmadığını ve <b>DentisToday</b> ‘i gayri kabili rücu ve ibra ettiğini kabul,
beyan ve taahhüt eder. Kullanıcı’nın Platform üzerinden yaptığı işlem ve
görüşmelere ilişkin hukuki ve cezai sorumluluk kendisine aittir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.15.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, hesap bilgileri ve
şifresinin güvenliği ile kendi hesabı üzerinden gerçekleştirilen işlemlerden
kendisi sorumludur. Kullanıcı’nın hesabından yapılan işlemler Kullanıcı
tarafından gerçekleştirilmiş olarak kabul edilir. Bu nedenle Kullanıcı
hesabının güvenliğinden kendi sorumludur. Kullanıcı, hesabı üzerinden
gerçekleştirilen işlemlerin kendisi tarafından yapıldığını, bu işlemlerden
kaynaklanan sorumluluğun peşin olarak kendisinde olduğunu, hesabında
gerçekleşmiş olan işlemlerin kendisi tarafından yapılmadığı yolunda herhangi
bir def’i ve/veya itiraz ileri süremeyeceğini söz konusu defi ve/veya itiraza
dayalı olarak yükümlülüklerinden kaçınamayacağını kabul, beyan ve taahhüt eder.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.16.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, Platform üzerinden <b>DentisToday’e</b>
bildirdiği kişisel bilgilerini, iletişim bilgilerini güncel tutmak zorundadır.
Kullanıcı’nın söz konusu bilgilerinde bir değişim olduğunda Kullanıcı derhal <b>DentisToday’e</b>
bildirmek zorunda olup bildirmemesi durumunda oluşabilecek zararlardan <b>DentisToday</b>
zorunlu tutulamaz. <b>DentisToday</b> hizmet sağlayıcı olarak yapacağı tüm
bilgileri sistemde kayıtlı iletişim kanalları üzerinden yapmak suretiyle
bildirim yükümlülüklerini yerine getirmiş sayılacaktır.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.17.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b>, kişisel
verilerin korunması noktasında bireylerin temel hakı olan kişisel verilerin
korunması hakkına uygun şirket politikası ile hizmetleri sunmaktadır. <b>DentisToday</b>
Kullanıcı’nın verilerini ve hesabının güvenliğini sağlayabilmek için tüm özeni
gösterecektir. Kullanıcı’nın hesap güvenliğini sağlayacak olan <b>DentisToday</b>,
Kullanıcı’nın kendi hatasından kaynaklanan veya <b>DentisToday</b> dışı
gerçekleşen hiçbir türlü zarardan sorumlu olmayacaktır. Kullanıcı, bu
durumlarda <b>DentisToday’i</b> gayri kabili rücu olarak ibra ettiğini kabul,
beyan ve taahhüt eder.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.18.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, işbu Sözleşme’deki
fikri mülkiyet hakları bölümündeki hükümlere riayet etmek zorundadır.
Kullanıcı’nın bu hükümlere aykırı hareket etmesi Sözleşme’nin tek taraflı ve
haklı nedenle fesih nedeni olup doğacak olan tüm hukuki ve cezai sorumluluk
Kullanıcı’ya aittir. Kullanıcı’nın üçüncü kişilere hesabını kullandırması
sonucu doğacak olan mağduriyetlerde de sorumluluk Kullanıcı’ya ait olup <b>DentisToday</b>,
Kullanıcı ve ilişkili kişilerden tüm zararlarını talep edebilecektir. Kullanıcı
bu hükmü okuyup kabul etiğini beyan eder.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.20.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, <b>DentisToday</b>’in
sistemine makul olmayan yüklemeler yaratacak ve teknik işleyişine zarar verecek
faaliyette bulunamaz. Platform üzerinden veri madenciliği veri taraması,
örümcek, web crawler ve robot ile al sat gibi faaliyetlerde bulunamaz.
Platformun içeriğine zarar verici zararlı yazılımları yükleyemez. Platformu
ahlaka ve hukuka aykırı kullanmayacak olup herhangi bir rehber yaratmak, veri
tabanı ve kayıt tutma aracı oluşturmak amacıyla kullanamaz. Kullanıcı hesaba
robot yöntemi ile girmeyeceğini ve Platforma herhangi zarar verici yazılımsal
faaliyette veya başka bir faaliyette bulunmayacağını ve buna göre tedbir
aldığını kabul ve beyan eder. Bu hükümdeki aykırılıklar nedeniyle <b>DentisToday</b>’in
işbu Sözleşme ve mevzuattaki talep ile dava ya da takip hakları saklıdır.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.21.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, dilediği zaman Platform’da
açmış olduğu hesabını hiçbir gerekçe göstermeksizin kapatılmasını talep
edebilir. Kullanıcı hesap kapatma talebini sadece <b>DentisToday</b>’e
iletebilir ve talebi ilettikten sonra 30 gün içinde herhangi bir yasal engel
olmaması durumunda hesabı kapatılır. <b>DentisToday</b>, mevzuat veya Taraflar’ın
menfaatleri gereğince hesabı kapatılan Kullanıcı’nın kişisel verilerini
mevzuatta ve şirket veri politikasında belirtilen süre kadar saklayabilir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.22.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b> Kullanıcı’ların
verdiği ve veri tabanında bulunan kişisel bilgileri, Kullanıcı’nın Mobil Uygulama’yı
kullanım, ziyaret bilgilerini (sayılanlarla sınırlı olmamak üzere ziyaret
süresi, zamanı, görüntülenen sayfalar) istatistiksel bilgilere dönüştürmek,
reklam ve pazarlama alanında kullanmak, yeni kampanya ve aktiviteler hakkında
bilgi vermek, kullanıcıların genel eğilimlerini belirlemek, Mobil Uygulama
içeriğini ve servislerini zenginleştirmek için kullanma ve kullandırma hakkına
sahiptir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>4.23.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b>,<b> </b>Mobil<b>
</b>Uygulama içerisinde banner ve linkler ile tanıtımını yaptığı/yapacağı
sitelerin ve/veya başkaca uygulamaların içeriklerinden sorumlu değildir.</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>5.
KİŞİSEL VERİLERİN KORUNMASI</span></b></h1>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>5.1</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b>,
Kullanıcı’lardan elde ettiği kişisel verilerin işlenmesiyle ilgili, 6698 sayılı
Kişisel Verilerin Korunması Kanunu’na uygun hareket etmektedir. Platform
üzerinden erişilebilecek, <b>DentisToday</b> Kişisel Verileri Koruma
Politikası, Çerez Politikası, Aydınlatma Metni ve Açık Rıza Beyanı işbu
Sözleşmenin ayrılmaz birer parçasıdır. Alınan tüm tedbirler ve yasal önlemlere
rağmen, kişisel verilerin herhangi bir sebeple <b>DentisToday</b> bilgisi ve
dahili dışında kullanılması, çalınması veya işlenmesinden <b>DentisToday</b> sorumlu
tutulamaz.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>5.2</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, <b>DentisToday</b> ile
arasındaki sözleşmenin her ne nedenle olursa olsun sona ermesi üzerine, derhal <b>DentisToday</b>
tarafından kendisine sözleşmenin amacının gerçekleşmesini sağlamak amacıyla
teslim edilen kişisel verileri, ilgili mevzuat ve işbu Sözleşmenin imzasından
sonra dahi yayınlanarak yürürlüğe girecek düzenlemeler ile öngörülecek usullere
uygun şekilde imha edecek ya da anonim hale getirecektir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>5.3</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b> ile Kullanıcı
arasında akdedilen sözleşmeler uyarınca, Kullanıcı tarafından bir kişiye ait
verilerin <b>DentisToday</b>’e aktarılmasının söz konusu olduğu her halde Kullanıcı
ilgili veri sahiplerine yürürlükte bulunan mevzuata uygun ve mevzuat kapsamında
gerekli bilgilendirmelerin yapılması ve ilgili kişilerden mevzuata uygun içerik
ve formatta gerekli izin ve onayları alacağını ve bilgilendirme tutanağı ve
muvafakatnamelerin <b>DentisToday</b>’e kişisel veri ile birlikte
aktarılacağını taahhüt eder.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>5.4</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, kişisel verilerin <b>DentisToday</b>’e
aktarımına ya da <b>DentisToday</b> tarafından kendisine veri aktarımı
yapılması hallerinde halinde aktarım ve muhafazaya ilişkin olarak en güncel ve
güvenli süreci uygulayacağını, mümkün olan tüm güvenlik tedbirlerini alacağını,
fiziksel ve dijital yetkisiz erişim ya da aktarımı önlemek için gerekli her
türlü önlemi alacağını kabul, beyan ve taahhüt eder.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>5.5</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, <b>DentisToday</b>
tarafından aktarılan kişisel verilere herhangi bir şekilde yetkisiz erişim ya
da uygunsuz aktarım gerçekleşmesi durumunda bu durumu derhal <b>DentisToday</b>’e
bildireceğini, bu durumdan doğabilecek zararların en aza indirilmesi, doğan
zararın giderilmesi için <b>DentisToday</b> ile iş birliği içerisinde olacağını
ve <b>DentisToday</b> tarafından talep edilen her türlü bilgi, belge ve desteği
gecikmeksizin sağlayacağını kabul ve taahhüt eder.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>&nbsp;</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>6. FİKRİ
MÜLKİYET HAKLARI</span></b></h1>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>6.1</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Platform’un tüm eser sahipliği
hakları <b>DentisToday</b>’e aittir. <b>DentisToday</b>, Platform’a son verme,
güncellememe veya çıkarmama, Platform ücretlerini, özelliklerini, teknik
özelliklerini, kapasitesini, işlevlerini ve genel kullanılabilirliğini veya
özelliklerini değiştirme hakkı dahil olmak üzere işbu Sözleşme kapsamındaki tüm
haklarını açıkça saklı tutar. Logo, fikir, metin, tasarım, alan adı, yazılım
kodları, ara yüzlerin içerikleri, çizimleri, algoritmaları, Platform’daki
videolar, know-how ve show-how içeren her türlü bilgi ve bunlarla da sınırlı
olmamak kaydıyla kullandığı tüm materyaller <b>(“Materyaller”)</b> iş modelleri
ve iş metodu da dahil olmak üzere Platform üzerindeki her ürünün fikri ve sınai
mülkiyet hakları <b>DentisToday</b>’e aittir. <b>DentisToday</b>, fikri ve
sınai mülkiyet haklarına sahip olduğu hizmet, sayfa ve servislerin,
Materyaller’in kopyalanmasına, çoğaltılmasına, işlenmesine, tersine
mühendisliğe tabi tutulmasına cevaz vermez. Kullanıcı, bu hükme aykırı hareket
etmeyeceğini kabul, beyan ve taahhüt eder. İşbu hükümde yer alan fikri ve sınai
mülkiyet hakları sınırsız nitelikte olup <b>DentisToday</b> bunların
hiçbirinden feragat etmemiştir. <b>DentisToday</b>, fikri sınai mülkiyet
haklarına ihlal tespit ettiği anda ihlal eden Kullanıcı ve Kullanıcı ile
ilişkili kişilere başta Kullanıcı Hesaplarına ilişkin tedbirler olmak üzere her
türlü hukuki yola başvurabilir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>6.2</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b>, Kullanıcı’nın
işletme adı, hekim adı bilgisi ve logosunu referans amacıyla basılı ve dijital
ortamda kullanabilir. <b>DentisToday</b> referans gösterme işlemlerinde
Kullanıcı’ nın ticari itibarının korunmasını ön planda tutar.</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>7.HUKUKİ
VE CEZAİ MÜEYYİDELER</span></b></h1>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>7.1.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, Platform’u Türkiye
Cumhuriyeti kanun ve mevzuatlarına uygun kullanacağını taahhüt eder. Platformda
Kullanıcıya ait hesap üzerinden gerçekleştirilen her türlü eylem ve işlem
nedeniyle oluşabilecek tüm hukuki ve cezai sorumluluk Kullanıcıya aittir.
Kullanıcının yasa ve mevzuatlara aykırı işlemleri nedeniyle <b>DentisToday</b>’in
hiçbir şekilde hukuki ya da cezai sorumluluğu bulunmamaktadır.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>7.2.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, yalnızca hukuka uygun
amaçlar doğrultusunda Platform’u kullanabilir. Kullanıcı tarafından Platformun
hukuka aykırı amaçlarla kullanılması ya da başka bir üçüncü kişiye
kullandırtılmasının tespiti halinde <b>DentisToday</b>, Kullanıcı’ya ait tüm
bilgi, veri ve işlem kayıtlarını yetkili merciler ile paylaşma hakkı ve
yetkisine sahiptir. Bu durum gizliliğin ihlali kapsamında değerlendirilemez ve
yapılan bu paylaşım dolayısıyla <b>DentisToday</b>’e herhangi bir sorumluluk
atfedilemez.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>7.3.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı’nın hesabında hukuka
aykırı bir işlemin tespit edilmesi halinde <b>DentisToday</b>, her türlü hukuki
yola başvurma ve hesabın dondurulması, hesabın kalıcı veya geçici olarak
silinmesi ya da Sözleşme’nin feshedilmesi gibi gerekli her türlü idari ve
güvenlik tedbirlerini alma hakkına sahiptir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>7.4.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, <b>DentisToday</b> ile
gerçekleştireceği sözlü, yazılı ve görüntülü iletişimde genel ahlak kuralları
ile dürüstlük kuralına aykırı, hakaret ve saygısızlık içeren, <b>DentisToday</b>’in
iyi niyetini suiistimal eden davranış ve söylemlerde bulunamaz. Kullanıcı’nın
söz konusu eylemlerini gerçekleştirmesi durumunda <b>DentisToday</b>,
Kullanıcı’ya ait hesabı dondurma, Kullanıcı’nın geçici veya sürekli olarak
hesaba erişimini engelleme ve hesabı faaliyet kapsamındaki işlemlere kapatma
haklarına sahip olup her türlü hukuki yola başvurma hakkını saklı tutar. Bu
maddenin uygulanması nedeniyle <b>DentisToday</b>’in hukuki ve cezai
sorumluluğuna gidilemez.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>7.5</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Kullanıcı, yazılı ve görsel medya
ile sosyal medya üzerinden temel uygulamada şikayetleri dışında <b>DentisToday</b>
ticari itibarına zarar verecek herhangi bir paylaşımda bulunamaz. Aksi halde <b>DentisToday</b>
mevzuattan doğan tüm yasal hakları saklı kalmak kaydıyla kullanıcının platforma
erişimini durdurabileceği gibi, Kullanıcı’ nın hesabını silebilecektir.</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>8.
GİZLİLİK</span></b></h1>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>8.1.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> İşbu Sözleşme’nin Kullanıcı
tarafından kabul edilmesi ve onaylanması halinde <b>DentisToday</b>, Kullanıcı’ya
ait olan her türlü bilgi ve veriyi gizli tutmayı ve gizlilik için her türlü
tedbiri alacağını taahhüt eder. Ancak Türkiye Cumhuriyeti kamu kurumlarının yetkili
makamları tarafından talep edilen bilgi ve belgeler; yasalar, KHK’lar ve sair
tüm mevzuat kapsamında yetkili makamlara sunulabilecektir. Bu durum gizliliğin
ihlali anlamına gelmemekle beraber doğacak herhangi bir zarardan <b>DentisToday</b>
sorumlu sayılamaz.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>8.2.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> Taraflar, 6698 sayılı Kişisel
Verilerin Korunması Hakkındaki Kanun kapsamında doğan hak ve yükümlülükleri
karşılıklı olarak kabul etmiş olup işbu Sözleşme ile söz konusu kanuna uygun
davranmayı kabul ve taahhüt etmektedirler.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>8.3.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b> üçüncü
kişilerle yapmış olduğu iş birliği kapsamında Mobil Uygulama içerisinde başka
sitelere bağlantı verebilir, üçüncü kişilerin sitelerine yönlendirme yapabilir
ya da formlarını yayınlayabilir. Aynı şekilde üçüncü kişilerle yapmış olduğu
anlaşma kapsamında farklı platformlar üzerinden kendi reklamını yayınlatabilir
ve bağlantı linkleri ile formlar yayınlayabilir. Bu durumda üçüncü kişilere ait
bağlantı yoluyla erişilen diğer sitelerin gizlilik ve çerez politikası ile her
türlü sair içeriğe ilişkin <b>DentisToday</b>’in sorumluluğuna gidilemez.
Başkaca şirket veya organizasyonlarla iş birliği yapılması, bu şirket veya
organizasyonlarla <b>DentisToday</b>’in herhangi bir bağının olduğu anlamına
gelmemektedir. Kullanıcı Site ya da Uygulama üzerinde yer alan herhangi bir
site ya da uygulama dışı link ile etkileşiminden kendisi sorumludur.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>8.4.</span></b><span lang=TR
style='font-family:"Times New Roman",serif'> <b>DentisToday</b>, Kullanıcı ile
arasında imzalamış olduğu Sözleşme’nin gerekliliklerini yerine getirmek veya
uygulamak amacıyla, Kullanıcı’nın haklarını veya güvenliklerini koruma
amacıyla, yetkili adli ya da idari makamlar tarafından usulüne uygun olarak
yürütülen bir araştırma veya soruşturma kapsamında Kullanıcılar ile ilgili
bilgi, belge veya veri talep edilmesi halinde, Kanun, KHK, Yönetmelik ile
yetkili kurum ve otoriteler tarafından yeni çıkarılan veya mevcut olarak
yürürlükte olan her türlü mevzuatın uyulmasının gerektirdiği hallerde <b>DentisToday</b>
işbu Sözleşme’nin gizlilik hükümlerinin dışına çıkarak her türlü bilgi, belge
ve veriyi üçüncü kişilerle paylaşabilecektir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>8.5</span></b><span lang=TR
style='font-family:"Times New Roman",serif'>. Kullanıcı’nın
öğrendiği/öğrenebileceği, elde ettiği ve edeceği tüm bilgiler; her türlü yazılı
ya da sözlü bilgi, <b>DentisToday</b> tarafından geliştirilmiş herhangi bir
bilgi, bilgi birikimi (know-how), Show-how, metod, yöntem, teknik, gelişme veya
endüstri alanında henüz duyulmamış, bilinmeyen bir fikir, uygulama, yöntem,
buluş, tasarım, model, eser, Kullanıcı’ya sağlanan bilgi, belge, dokümantasyon,
süreç ve benzerleri üzerindeki her türlü bilgi gizli bilgi kapsamındadır. Ayrıntılı
gizlilik, çerez, bilgi güvenliği politikası, diğer bilgilendirmeler ve KVKK
sorularınız için lütfen Mobil Uygulama’da yer alan KVKK ve diğer ilgili
metinleri inceleyiniz.</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>9.
MÜCBİR SEBEP</span></b></h1>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>Platform’un kullanılacağı cihaz ve sistemlerin kurulu
bulunduğu ülkede deprem, grev, kötü hava koşulları, salgın hastalıklar, ilan
edilmiş olsun ya da olmasın savaş, ambargo, ayaklanma, doğal afetler, hükümet
kararları, internet altyapısında yaşanan sorunlar ve/veya tamamen kapatılması,
telekomünikasyon altyapısının öngörülen olağan hizmetlerinin Uygulamanın kullanılabileceği
teknik yeterlilikte çalışmaması, durumları Sözleşme koşullarını doğrudan
etkilediği ölçüde mücbir sebeptir. Mücbir sebep süresinde, Taraflar’ın
sorumluluk ve vazifeleri askıya alınacak ve bu süre içinde sözleşme
hükümlerinin yerine getirilememesi nedeniyle herhangi bir yaptırım
uygulanmayacak veya Taraflar birbirlerinden zararların tazminini talep
etmeyecektir. Bu mücbir sebep durumu 1 (bir) aydan fazla sürer ise, Taraflar
başka herhangi bir sorumluluk altına girmeksizin bu Sözleşme’yi feshetme
hakkına sahip olacaktır.</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>10.DELİL</span></b></h1>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='line-height:
107%;font-family:"Times New Roman",serif'>Taraflar arasındaki herhangi bir
uyuşmazlıkta, </span><b><span lang=TR style='font-family:"Times New Roman",serif'>DentisToday</span></b><span
lang=TR style='line-height:107%;font-family:"Times New Roman",serif'>’in
tuttuğu müşteri işlemlerini de gösteren defter, belge ve elektronik ortam
kayıtları ile her türlü veri delil niteliğine haizdir. </span><b><span lang=TR
style='font-family:"Times New Roman",serif'>DentisToday</span></b><span
lang=TR style='line-height:107%;font-family:"Times New Roman",serif'>’in
sunucularında tutulan veriler, e-arşiv kayıtları ve her türlü elektronik
bilgiler bağlayıcı ve münhasır delil niteliğindedir. Kullanıcı, </span><b><span
lang=TR style='font-family:"Times New Roman",serif'>DentisToday</span></b><span
lang=TR style='line-height:107%;font-family:"Times New Roman",serif'> müşteri
deneyim ekibi veya başka bir yetkili personel ile yapmış olduğu telefon
görüşmesinin kaydedilebileceğini ve bu kayıtların herhangi bir uyuşmazlıkta
delil olabileceğini bilir. Kullanıcı bu hükmün 6100 sayılı Hukuk Muhakemeleri
Kanunu’nun 193. Maddesi uyarınca delil sözleşmesi olduğunu kabul, beyan ve
taahhüt eder.</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>11.
SÖZLEŞME’NİN AYRILABİLİRLİĞİ</span></b></h1>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>İşbu Sözleşme’nin herhangi bir hükmü, maddesi veya
koşulunun herhangi bir zamanda ve herhangi bir sebeple tamamen veya kısmen
resmi ve yetkili mercilerce geçersiz veya ifa edilemez kılınması halinde, işbu
Sözleşme’nin geri kalan kısmı bu durumdan etkilenmeyecek ve geçersiz kılınan
bölüm daha önce çıkarılmış gibi yorumlanacaktır. Taraflar, böyle bir durum söz
konusu olduğunda kanuni açıdan kabul edilebilecek ve işbu Sözleşme’nin ilgili
hükmüne mümkün olduğu kadar yakın alternatifler getiren değişikliği yapmak
amacıyla çalışmalarda bulunacaklardır.</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>12.
SÖZLEŞME’NİN TADİLİ</span></b></h1>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>DentisToday</span></b><span
lang=TR style='font-family:"Times New Roman",serif'>, işbu Sözleşme’yi ve
Platformda yer alan tüm yasal metinlerdeki hüküm ve koşulları ihbara gerek
kalmaksızın Platform’da yayımlayarak tek taraflı olarak değiştirme ve
güncelleme hakkına sahiptir. Yapılan değişiklikler Mobil Uygulamada
yayımlandığı andan itibaren hüküm ve sonuç doğurur.</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>13.
SÖZLEŞME’NİN FESHİ</span></b></h1>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>Taraflar, aralarındaki işbu Sözleşme’yi önceden
bildirmek kaydıyla tek taraflı olarak feshedebilir. Ayrıca;</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>· Kullanıcı’nın işbu Sözleşme hükümlerine aykırı
hareket etmesi veya Sözleşme hükümlerince kabul, beyan ve taahhüt ettiği
yükümlülüklere uygun hareket etmemesi halinde</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>· Kullanıcı’nın, <b>DentisToday</b>’in sunmuş olduğu
ürün ve hizmetleri kullanması sonucu girmiş olduğu borç yükümlülüklerini
ödemede temerrüde düşmesi halinde</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>· Kullanıcı’nın işbu Sözleşme çerçevesinde vermiş
olduğu bilgilerin ve belgelerin yanlış olması halinde,</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>· Kullanıcı’nın iflas ve aciz halinde olması ya da
yakın gelecekte olacağına ilişkin yeterince emarelerin bulunması halinde,</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>· İşbu sözleşmede yer alan diğer fesih sebeplerinin
gerçekleşmesi halinde, Sözleşmeyi tek taraflı olarak feshedebilecektir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=TR
style='font-family:"Times New Roman",serif'>14. SÖZLEŞMENİN DEVRİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>Kullanıcı’ nın hiçbir şart ve kayıt altında işbu
sözleşmeden doğan hak ve alacakları ile borç ve yükümlülüklerini 3 Kişilere
devir ve temlik hakkı bulunmamaktadır. </span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>15.
UYGULANACAK HUKUK</span></b></h1>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>İşbu Sözleşme hükümleri Türkiye Cumhuriyeti hukukuna
tabidir. İşbu Sözleşmeden kaynaklanan ya da bu Sözleşme ile bağlantılı her
türlü ihtilafta İstanbul Anadolu Mahkemeleri ve İstanbul Anadolu İcra
Müdürlükleri ile İstanbul Anadolu Tüketici Hakem Heyetleri münhasıran
yetkilidir.</span></p>

<h1 style='text-align:justify'><b><span lang=TR style='font-size:11.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:windowtext'>16.
KABUL VE YÜRÜRLÜK</span></b></h1>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>İşbu Sözleşme, Kullanıcı’nın <b>DentisToday</b>’e ait
Platform’a elektronik ortamda üye olması ile başlar. Kullanıcı, üye olurken
işbu Sözleşmede bulunan tüm maddeleri ve hükümleri ayrı ayrı okuyarak
Sözleşmenin tüm madde ve hükümlerinde ayrı ayrı her cümlesinde mutabık kaldığını
kabul, beyan ve taahhüt eder.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>İşbu Kullanıcı Sözleşmesi, 16 (on altı) ana maddeden
oluşmakta olup Kullanıcı tarafından her bir hükmü ayrı ayrı okunarak ve
bütünüyle anlaşılarak elektronik ortamda onaylanmak suretiyle, Kullanıcı
tarafından onay verildiği an yürürlüğe girmiştir.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>&nbsp;</span></p>

</div>

</body>

</html>

    `;

  return (
    <div
      style={{
        padding: '20px',
        fontSize: '16px',
        lineHeight: '1.5',
        fontFamily: 'Arial, sans-serif',
      }}
      dangerouslySetInnerHTML={{
        __html: i18n.language === 'en' ? en : tr,
      }}
    >

    </div>
  )
}