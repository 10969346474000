import React from 'react'
import { useTranslation } from 'react-i18next';
import boxImage from '../assets/image001.png';

export default function ConsentForm() {
  const { i18n } = useTranslation();
  
    const en = `
    <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<title>277401080629521ff0cfaad83541b404</title>
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
h1
	{margin-top:.05pt;
	margin-right:15.25pt;
	margin-bottom:0in;
	margin-left:.15in;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin:0in;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
@page WordSection1
	{size:595.0pt 842.0pt;
	margin:68.0pt 65.0pt 14.0pt 65.0pt;}
div.WordSection1
	{page:WordSection1;}
@page WordSection2
	{size:595.0pt 842.0pt;
	margin:80.0pt 65.0pt 14.0pt 65.0pt;}
div.WordSection2
	{page:WordSection2;}
-->
</style>

</head>

<body lang=EN-US style='word-wrap:break-word'>

<div class=WordSection1>

<h1 align=center style='margin-top:3.55pt;margin-right:184.3pt;margin-bottom:
0in;margin-left:184.4pt;margin-bottom:.0001pt;text-align:center;line-height:
123%'><span style='letter-spacing:-2.85pt'> </span><span style='letter-spacing:
-.05pt'>DENTISTODAY</span></h1>

<p class=MsoBodyText><b><span style='font-size:15.0pt'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:.05pt;margin-right:42.35pt;margin-bottom:
0in;margin-left:42.45pt;margin-bottom:.0001pt;text-indent:75.05pt;line-height:
123%'><b><span style='font-size:12.0pt;line-height:123%'>DECLARATION OF
EXPLICIT CONSENT<span style='letter-spacing:.05pt'> </span><span
style='letter-spacing:-.1pt'>REGARDING</span><span style='letter-spacing:-.45pt'>
</span><span style='letter-spacing:-.1pt'>YOUR</span><span style='letter-spacing:
.05pt'> </span><span style='letter-spacing:-.1pt'>PERSONAL</span><span
style='letter-spacing:-.65pt'> </span><span style='letter-spacing:-.1pt'>DATA</span><span
style='letter-spacing:-.7pt'> </span><span style='letter-spacing:-.1pt'>OF</span><span
style='letter-spacing:-.4pt'> </span><span style='letter-spacing:-.1pt'>SPECIAL</span><span
style='letter-spacing:-.65pt'> </span><span style='letter-spacing:-.05pt'>NATURE</span><span
style='letter-spacing:-.2pt'> </span><span style='letter-spacing:-.05pt'>TO</span>
<span style='letter-spacing:-.05pt'>BE</span></span></b></p>

<h1 style='margin-top:.1pt;margin-right:15.25pt;margin-bottom:0in;margin-left:
116.35pt;margin-bottom:.0001pt;text-indent:-100.75pt;line-height:123%'><span
style='letter-spacing:-.05pt'>PROCESSED</span><span style='letter-spacing:-.35pt'>
</span><span style='letter-spacing:-.05pt'>WITH</span><span style='letter-spacing:
-.1pt'> </span><span style='letter-spacing:-.05pt'>REGARD</span><span
style='letter-spacing:-.3pt'> </span><span style='letter-spacing:-.05pt'>TO</span><span
style='letter-spacing:-.3pt'> </span>THE<span style='letter-spacing:-.05pt'> </span>HEALTH<span
style='letter-spacing:-.35pt'> </span>WITHIN<span style='letter-spacing:-.3pt'>
</span>THE<span style='letter-spacing:-.05pt'> </span>SCOPE<span
style='letter-spacing:-.05pt'> </span>OF<span style='letter-spacing:-.75pt'> </span>THE<span
style='letter-spacing:-2.85pt'> </span>PROTECTION<span style='letter-spacing:
.35pt'> </span>OF<span style='letter-spacing:-.1pt'> </span>PERSONAL<span
style='letter-spacing:-.4pt'> </span>DATA<span style='letter-spacing:-.4pt'> </span>LAW</h1>

<p class=MsoBodyText><b><span style='font-size:13.0pt'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:.55pt'><b><span style='font-size:16.5pt'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-left:27.1pt;text-align:justify'>Our<span
style='letter-spacing:-.2pt'> </span>Dear<span style='letter-spacing:-.2pt'> </span>User,</p>

<p class=MsoBodyText style='margin-top:3.4pt;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-indent:
21.3pt;line-height:123%'>We require <b>your explicit consent </b>stated under
the following text, legally, for the purpose<span style='letter-spacing:-2.85pt'>
</span>of controlling our membership services to be regulated by determining in
line with your needs<span style='letter-spacing:-2.85pt'> </span>and wishes,
provision of preliminary diagnosis by the application which is the fundamental<span
style='letter-spacing:.05pt'> </span>aim<span style='letter-spacing:1.85pt'> </span>of<span
style='letter-spacing:1.85pt'> </span>our<span style='letter-spacing:1.85pt'> </span>application<span
style='letter-spacing:1.85pt'> </span>or<span style='letter-spacing:1.85pt'> </span>uploading<span
style='letter-spacing:1.85pt'> </span>the<span style='letter-spacing:1.85pt'> </span>required<span
style='letter-spacing:1.85pt'> </span>photographs<span style='letter-spacing:
1.85pt'> </span>and<span style='letter-spacing:1.85pt'> </span>X-ray<span
style='letter-spacing:1.85pt'> </span>and<span style='letter-spacing:-2.85pt'>   
</span>your<span style='letter-spacing:.05pt'> </span>other<span
style='letter-spacing:.05pt'> </span>health<span style='letter-spacing:.05pt'> </span>data<span
style='letter-spacing:.05pt'> </span>required<span style='letter-spacing:.05pt'>
</span>to<span style='letter-spacing:.05pt'> </span>the<span style='letter-spacing:
.05pt'> </span>application,<span style='letter-spacing:.05pt'> </span>including<span
style='letter-spacing:.05pt'> </span>your<span style='letter-spacing:.05pt'> </span>identity<span
style='letter-spacing:3.0pt'> </span>validation,<span style='letter-spacing:
.05pt'> </span>producing the accurate health solution or the best result that
you need, providing you with<span style='letter-spacing:.05pt'> </span>information
on the most appropriate and the closest clinic or hospital for you and
performing<span style='letter-spacing:.05pt'> </span>your procedures for
check-in and check-out in the quickest safest manner, and providing you<span
style='letter-spacing:.05pt'> </span>with<span style='letter-spacing:-.05pt'> </span>adequate<span
style='letter-spacing:-.05pt'> </span>and required service.</p>

<p class=MsoBodyText style='margin-top:.55pt;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-indent:
21.25pt;line-height:123%'>While you are getting benefit from the membership
services, you provide your x-ray, oral<span style='letter-spacing:-2.85pt'> </span>photograph,
other health information which is your personal health data of special nature.
Our<span style='letter-spacing:-2.85pt'> </span>purpose to request such data of
yours is to trying to ensure that you use our application with<span
style='letter-spacing:.05pt'> </span>the<span style='letter-spacing:1.1pt'> </span>most<span
style='letter-spacing:1.1pt'> </span>useful<span style='letter-spacing:1.15pt'>
</span>and<span style='letter-spacing:1.1pt'> </span>maximum<span
style='letter-spacing:1.15pt'> </span>efficient<span style='letter-spacing:
1.1pt'> </span>time<span style='letter-spacing:1.15pt'> </span>while<span
style='letter-spacing:1.1pt'> </span>you,<span style='letter-spacing:1.15pt'> </span>our<span
style='letter-spacing:1.1pt'> </span>dear<span style='letter-spacing:1.15pt'> </span>members<span
style='letter-spacing:1.1pt'> </span>get<span style='letter-spacing:1.15pt'> </span>benefit<span
style='letter-spacing:1.1pt'> </span>from<span style='letter-spacing:-2.9pt'> </span>the
help and activities in the application and is trying to reach that you get
benefit from the<span style='letter-spacing:.05pt'> </span>most<span
style='letter-spacing:-.05pt'> </span>beneficial health service.</p>

<p class=MsoBodyText style='margin-top:.35pt;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-indent:
21.25pt;line-height:123%'>Due to the reasons stated, we kindly would like to
inform you that we process your<span style='letter-spacing:.05pt'> </span>health<span
style='letter-spacing:.05pt'> </span>data<span style='letter-spacing:.05pt'> </span>which<span
style='letter-spacing:.05pt'> </span>are<span style='letter-spacing:.05pt'> </span>your<span
style='letter-spacing:.05pt'> </span>personal<span style='letter-spacing:.05pt'>
</span>data<span style='letter-spacing:.05pt'> </span>of<span style='letter-spacing:
.05pt'> </span>special<span style='letter-spacing:.05pt'> </span>nature<span
style='letter-spacing:.05pt'> </span>in<span style='letter-spacing:-2.85pt'> </span>accordance
with the Protection of Personal Data Law numbered 6698 and all of the related<span
style='letter-spacing:.05pt'> </span>legislation, we retain the same
technically appropriate in accordance with the legislation,<span
style='letter-spacing:.05pt'> </span>limited only to the activity in practice
and we do no and will not use your personal data of<span style='letter-spacing:
.05pt'> </span>special<span style='letter-spacing:-.05pt'> </span>nature<span
style='letter-spacing:-.05pt'> </span>processed<span style='letter-spacing:
-.05pt'> </span>other than<span style='letter-spacing:-.05pt'> </span>the<span
style='letter-spacing:-.05pt'> </span>activities<span style='letter-spacing:
-.05pt'> </span>and controls<span style='letter-spacing:-.05pt'> </span>stated
above.</p>

<p class=MsoBodyText style='margin-top:.35pt;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;text-indent:
21.25pt;line-height:123%'>Your health data provided by you on the Website or
Mobile Application can only be reviewed by the physician that is authorized by
you. For this purpose, we inform you that your health data can be transferred
only to physicians who have been given permission to access your information.</p>

<p class=MsoBodyText><span style='font-size:10.0pt'>                </span></p>

<h1 style='margin-top:4.5pt;margin-right:369.5pt;margin-bottom:0in;margin-left:
.15in;margin-bottom:.0001pt;line-height:213%'>Name Surname:<span
style='letter-spacing:-2.85pt'> </span>Date:</h1>

<p class=MsoBodyText style='margin-top:.5pt'>

<table cellpadding=0 cellspacing=0 align=left>
 <tr>
  <td width=93 height=0></td>
 </tr>
 <tr>
  <td></td>
  <td><img width=158 height=35
  src="${boxImage}"></td>
 </tr>
</table>

<br clear=ALL>
</p>

<p class=MsoBodyText style='margin-top:2.6pt;margin-right:0in;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt'>I<span style='letter-spacing:-.15pt'>
</span>accept.</p>

</div>

<span style='font-size:12.0pt;font-family:"Times New Roman",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection2>

<p class=MsoBodyText style='margin-top:.15pt;margin-right:0in;margin-bottom:
.05pt;margin-left:0in'><span style='font-size:8.5pt'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-left:5.35pt'><img width=158 height=35
src="${boxImage}"></p>

<p class=MsoBodyText style='margin-top:3.3pt;margin-right:0in;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify'>I<span
style='letter-spacing:-.1pt'> </span>do<span style='letter-spacing:-.05pt'> </span>not<span
style='letter-spacing:-.1pt'> </span>accept.</p>

<p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:10.5pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:5.75pt;margin-bottom:
0in;margin-left:5.8pt;margin-bottom:.0001pt;text-align:justify;line-height:
100%'>* Within the scope of the DentisToday application operated by AIDENT
SAĞLIK VE DİŞ TEKNOLOJİLERİ ANONİM ŞİRKETİ Policy<span style='letter-spacing:
-.1pt'> </span>of<span style='letter-spacing:-.1pt'> </span>Protection<span
style='letter-spacing:-.1pt'> </span>and<span style='letter-spacing:-2.65pt'> </span>Process<span
style='letter-spacing:.05pt'> </span>of<span style='letter-spacing:.05pt'> </span>Personal<span
style='letter-spacing:.05pt'> </span>Data<span style='letter-spacing:.05pt'> </span>which<span
style='letter-spacing:.05pt'> </span>has<span style='letter-spacing:.05pt'> </span>been<span
style='letter-spacing:.05pt'> </span>submitted<span style='letter-spacing:.05pt'>
</span>for<span style='letter-spacing:.05pt'> </span>your<span
style='letter-spacing:.05pt'> </span>information<span style='letter-spacing:
.05pt'> </span>by<span style='letter-spacing:.05pt'> </span>DentisToday<span
style='letter-spacing:.05pt'> </span>in<span style='letter-spacing:.05pt'> </span>accordance
with the related provisions of the Protection of the Personal Data Law numbered
6698 (the<span style='letter-spacing:-2.6pt'> </span>“PPDL”); I declare that I
give my explicit consent regarding the process, transfer, retention for the<span
style='letter-spacing:.05pt'> </span>period of time required, updating of my
personal data including my personal data of special nature by<span
style='letter-spacing:.05pt'> </span>DentisToday, limited to the process for
which it is processed and that the necessary clarification in<span
style='letter-spacing:.05pt'> </span>this regard has been made to me, and that
I have read and understood the DentisToday ,Policy of<span style='letter-spacing:
.05pt'> </span>Protection<span style='letter-spacing:-.05pt'> </span>and
Process<span style='letter-spacing:-.05pt'> </span>of Personal<span
style='letter-spacing:-.05pt'> </span>Data<span style='letter-spacing:-.05pt'> </span>and<span
style='letter-spacing:-.05pt'> </span>Clarification<span style='letter-spacing:
-.2pt'> </span>Text.</p>

</div>

</body>

</html>

    `;
    const tr = `
    <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:106%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
	{page:WordSection1;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
line-height:115%'><b><span lang=TR style='font-family:"Times New Roman",serif'>DENTISTODAY</span></b></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:-14.2pt;
margin-bottom:0in;margin-left:-14.2pt;text-align:center;line-height:115%'><b><span
lang=TR style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:-14.2pt;
margin-bottom:0in;margin-left:-14.2pt;text-align:center;line-height:115%'><b><span
lang=TR style='font-family:"Times New Roman",serif'>KİŞİSEL VERİLERİN KORUNMASI
KANUNU KAPSAMINDA </span></b></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:-14.2pt;
margin-bottom:0in;margin-left:-14.2pt;text-align:center;line-height:115%'><b><span
lang=TR style='font-family:"Times New Roman",serif'>SAĞLIK İLE İLGİLİ İŞLENECEK
OLAN </span></b></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:-14.2pt;
margin-bottom:0in;margin-left:-14.2pt;text-align:center;line-height:115%'><b><span
lang=TR style='font-family:"Times New Roman",serif'>ÖZEL NİTELİKLİ KİŞİSEL
VERİLERİNİZ</span></b></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:-14.2pt;
margin-bottom:0in;margin-left:-14.2pt;text-align:center;line-height:115%'><b><span
lang=TR style='font-family:"Times New Roman",serif'>İLE İLGİLİ AÇIK RIZA BEYANI</span></b></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:-14.2pt;
margin-bottom:0in;margin-left:-14.2pt;text-align:center;line-height:115%'><b><span
lang=TR style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:-14.2pt;
margin-bottom:0in;margin-left:-14.2pt;text-align:center;line-height:115%'><b><span
lang=TR style='font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
0in;margin-left:-14.2pt;line-height:115%'><span lang=TR style='font-family:
"Times New Roman",serif'>            Değerli Kullanıcımız,</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
0in;margin-left:-14.2pt;line-height:115%'><span lang=TR style='font-family:
"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
0in;margin-left:-14.2pt;text-align:justify;text-indent:35.45pt;line-height:
115%'><span lang=TR style='font-family:"Times New Roman",serif'>Sizlerin
ihtiyaç ve istekleri doğrultusunda belirlenerek düzenlenecek üyelik
hizmetlerimizin kontrolleri ile  DentisToday mobil uygulamamızın temel amacı
olan, uygulama ile ön tanı veya gerekli fotoğraf ve röntgenler ile gerekli
görülen diğer sağlık verilerinizin uygulamaya yüklenmesi, yine sizlerin kimlik
doğrulamanız dahil, doğru sağlık çözümü veya ihtiyacınız olan en iyi sonucu
üretebilmek, size en uygun ve en yakın klinik veya hastaneyi iletebilmek ve
giriş ve çıkış işlemlerinizi en hızlı ve güvenli şekilde yapabilmeniz ile
sizlere yeterli ve gerekli hizmeti sağlayabilmemiz amacıyla, yasal olarak
aşağıdaki metinde yer alan <b>açık rızanıza</b> ihtiyaç duymaktayız.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
0in;margin-left:-14.2pt;text-align:justify;text-indent:35.45pt;line-height:
115%'><span lang=TR style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
0in;margin-left:-14.2pt;text-align:justify;line-height:115%'><span lang=TR
style='font-family:"Times New Roman",serif'>Üyelik hizmetlerinden faydalanırken
uygulamamızda, röntgen, ağız içi fotoğraf, diğer sağlık bilgileriniz olmak
üzere özel nitelikli sağlık verilerinizi iletmektesiniz. Bu verilerinizi talep
etmemizdeki amacımız, siz değerli üyelerimizin uygulamadaki yardım ve
faaliyetlerimizden yararlanırken en faydalı ve maksimum seviyede verimli süre
ile uygulamamızı kullanmanızı sağlamaya çalışmak ve sizlere en faydalı sağlık
hizmetinden yararlanmanıza ulaşmaya çalışmaktadır.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
0in;margin-left:-14.2pt;text-align:justify;line-height:115%'><span lang=TR
style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
0in;margin-left:-14.2pt;text-align:justify;text-indent:35.45pt;line-height:
115%'><span lang=TR style='font-family:"Times New Roman",serif'>Açıklanan
sebeplerle, sağlık verileriniz olan özel nitelikli kişisel verilerinizi, 6698
sayılı Kişisel Verilerin Korunması Kanunu ve ilgili tüm mevzuata uygun olarak
işlediğimizi, mevzuata uygun olarak sadece uygulamadaki faaliyet ile sınırlı
olmak üzere yine teknik açıdan uygun olarak sakladığımızı ve işlenen özel
nitelikli kişisel verilerinizi yukarıda sayılan faaliyetler ile kontrollerin
dışında kullanmadığımızı ve kullanmayacağımızı siz değerli üyelerimizin
bilgisine sunmak isteriz. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
0in;margin-left:-14.2pt;text-align:justify;text-indent:35.45pt;line-height:
115%'><span lang=TR style='font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
0in;margin-left:-14.2pt;text-align:justify;text-indent:35.45pt;line-height:
115%'><span lang=TR style='font-family:"Times New Roman",serif'>Tarafınızca Mobil
Uygulama üzerinden sunulan sağlık verileriniz yalnızca tarafınızca izin verilen
hekim tarafından incelenebilecektir. Bu amaçla yalnızca tarafınızca
bilgilerinize erişim izni verilen hekimlerle sağlık verilerinizin
aktarılabileceğini bilgilerinize sunarız. <b>Mobil uygulamada bu metne onay
vermeniz halinde özel nitelikteki kişisel verilerinizin amaca uygun olarak
hekimler ile paylaşılabilecektir.</b></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
8.0pt;margin-left:-14.2pt;text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
8.0pt;margin-left:-14.2pt;text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
8.0pt;margin-left:-14.2pt;text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:-14.2pt;margin-bottom:
8.0pt;margin-left:-14.2pt;text-align:justify'><span lang=TR style='font-family:
"Times New Roman",serif'>* AIDENT SAĞLIK VE DİŞ TEKNOLOJİLERİ ANONİM ŞİRKETİ tarafından
işletilen DentisToday tarafından, 6698 sayılı Kişisel Verilerin Korunması
Kanunu’nun (“KVK Kanunu”) ilgili hükümlerine uygun olarak bilginize sunulan Aıdent
Sağlık Kişisel Verilerin Korunması ve İşlenme Politikası ile Aydınlatma
Bildirimi sınırları çerçevesinde; kişisel verilerim ve özel nitelikli kişisel
verilerim de dahil olmak üzere Aıdent Sağlık tarafından işlenme amacı ile
sınırlı olmak üzere işlenmesine, aktarılmasına, gereken süre zarfında
saklanmasına, güncellenmesine ilişkin açık rızam olduğunu ve bu hususta
tarafıma gerekli aydınlatmanın yapıldığını, iş bu metni, Aıdent Sağlık Kişisel
Verilerin Korunması ve İşlenme Politikası’nı ve Aydınlatma Metni’ni okuduğumu
ve anladığımı beyan ederim. </span></p>

</div>

</body>

</html>

    `;
  
  
    return (
      <div
        style={{
          padding: '20px',
          fontSize: '16px',
          lineHeight: '1.5',
          fontFamily: 'Arial, sans-serif',
        }}
        dangerouslySetInnerHTML={{
          __html: i18n.language === 'en' ? en : tr,
        }}
      >
  
      </div>
    )
  }