import { Box, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import logo from '../assets/aident-logo.png';

export default function SecurityPolicy() {

  const { i18n } = useTranslation();

  const en = ``;
  const tr = ``;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: 'auto',
        padding: '20px',
      }}
    >
      <img src={logo} alt="Aident Logo" />
      <Typography
        variant='h1'
        sx={{
          fontSize: '24px',
          fontWeight: 700,
          textAlign: 'center',
          mt: 2,
        }}
      >
        BİLGİ GÜVENLİĞİ POLİTİKAMIZ
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '1.5',
          mt: 2,
        }}
      >
        AIDENT SAĞLIK VE SAĞLIK HİZMETLERİ ARAŞTIRMA GELİŞTİRME İTH.İHR.SAN.TİC.LTD. ŞTİ.  kritik öneme sahip sistemlerinde yer alan tüm bilgi varlıklarını korumayı, bilgi güvenliği çerçevesinde mevzuatla, sözleşmelerle ve uluslararası standartlarla uyumlu çalışmayı, bilgi güvenliği yönetim sistemindeki riskleri yönetmeyi, düzeltici ve sürekli iyileştirici faaliyetlerde bulunmayı ve kuruluş amacına uygun olarak sürdürülebilir bir sistem kurmayı taahhüt eder.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 4,
          }}
        >
          <Typography>
            ŞİRKET MÜDÜRÜ
          </Typography>
          <Typography>
            Mehmet Serkan ŞAHİNER
          </Typography>
          <Typography>
            13.05.2024
          </Typography>
        </Box>
      </Box>
    </Box>
    // <div
    //   style={{
    //     padding: '20px',
    //     fontSize: '16px',
    //     lineHeight: '1.5',
    //     fontFamily: 'Arial, sans-serif',
    //   }}
    //   dangerouslySetInnerHTML={{
    //     __html: i18n.language === 'en' ? en : tr,
    //   }}
    // >

    // </div>
  )
}
